import { Breadcrumbs, Button, Card, CircularProgress, Link, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import Appbar from '../../../components/Appbar'
import DrawerUI from '../../../components/Drawer'
import Body from '../../../layout/Body'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import * as yup from "yup";
import SnackBarUI from '../../../comman/SnackBarUI';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../../comman/LoadingUI'
import { Box } from '@mui/system'
import { getMetaAction } from '../../../redux/toolkit/admin/config/getMeta'
import { editMetaAction } from '../../../redux/toolkit/admin/config/editMeta'



let breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/config-meta'>Config Meta</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Admin</Typography>
    </Stack>

)


// schema
const configSchema = yup.object({
    support_email: yup.string().required('Email is required.'),
    support_mobile: yup.string().required('Mobile Numer is required.'),
}).required();


export default function ConfigMeta() {

    const getMeta = useSelector(state => state.getMeta)
    const { data: configData, loading } = getMeta
    const editMeta = useSelector(state => state.editMeta)
    const { status, message } = editMeta

    const dispatch = useDispatch()

    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(configSchema) });
    const [snack, setSnack] = useState(false)

    const handleFAQ = async (data) => {
        let updateFAQ = await dispatch(editMetaAction({ config_id: configData?._id, ...data }))
        if (updateFAQ?.payload?.status == 200) {
            dispatch(getMetaAction())
        }
        setSnack(true)
        reset()
    }

    useEffect(() => {
        dispatch(getMetaAction())
    }, [dispatch])


    return (
        <>
            <Helmet>
                <title>Quick survey | home</title>
            </Helmet>


            <Appbar breadcrumb={breadcrumb} />
            <DrawerUI />
            <Body>
                <Card sx={{ p: 2 }}>
                    <form onSubmit={handleSubmit(handleFAQ)}>
                        {loading ? <LoadingUI />
                            : <>

                                <Stack spacing={2}>
                                    {configData?.support_email && <TextField type='text' defaultValue={configData?.support_email} label="Support Email" variant="outlined" placeholder='support email' {...register('support_email')} error={errors?.support_email?.message} helperText={errors?.support_email?.message} />}
                                    {configData?.support_mobile && <TextField type='text' defaultValue={configData?.support_mobile} label="support Mobile" variant="outlined" placeholder='Support mobile' {...register('support_mobile')} error={errors?.support_mobile?.message} helperText={errors?.support_mobile?.message} />}
                                    <Box><Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Edit Config'}</Button></Box>
                                </Stack>
                            </>}

                    </form>
                </Card>
            </Body>

            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}
