import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SnackBarUI from '../../comman/SnackBarUI';
import { getPublisherAction } from '../../redux/toolkit/publisher/getPublisher';
import { verifyPublisherAction } from '../../redux/toolkit/publisher/verifyPublisher';
import LoadingUI from '../../comman/LoadingUI';

export default function VerfiedPublisherDailog({ publisher_id, state, setState }) {
    // state
    const dispatch = useDispatch()
    const delCat = useSelector(state => state.verifyPublisher)
    const { status, message,loading} = delCat

    const [snack, setSnack] = useState(false)

    // fn
    const handleClose = () => {
        setState(false)
    }
   
    const delCatHandle = async () => {
        const publisherStatus = await dispatch(verifyPublisherAction({
            publisher_id: publisher_id
        }))
        if (publisherStatus?.payload?.status == 200) {
           await dispatch(getPublisherAction());
           setTimeout(()=>{setState(close)},500)
           
        }
        setSnack(true)
        
     
    }

    return (
        <>
            <Dialog open={state} onClose={handleClose}>
                <DialogTitle>Verify publisher !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to verify this publisher?
                    </DialogContentText>
                </DialogContent>
                { loading ? <LoadingUI/> : <DialogActions>
                    <Button autoFocus onClick={delCatHandle}> verify</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>}
            </Dialog>


            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>

    )
}


VerfiedPublisherDailog.propTypes = {
    publisher_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any
};