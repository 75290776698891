import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SnackBarUI from '../../comman/SnackBarUI'
import { getSurveyAction } from '../../redux/toolkit/survey/getSurvey'
import { surveyStatusAction } from '../../redux/toolkit/survey/surveyStatus'
import PropTypes from 'prop-types';

export default function UnliveSurvey({ survey_id, state, setState }) {


    // state
    const dispatch = useDispatch()
    const surveyState = useSelector(state => state.surveyStatus)
    const { status: surveyStatus, message: surveyMsg } = surveyState

    const [snack, setSnack] = useState(false)

    // fn
    const handleClose = () => {
        setState(false)
    }

    const handleUnlive = async () => {
        const unliveSurveyData = await dispatch(surveyStatusAction({ survey_id: survey_id, status: 'paused' }))
        if (unliveSurveyData?.payload?.status == 200) {
            await dispatch(getSurveyAction({ is_deleted: false }))
            setSnack(true)
        }
        setSnack(true)
        setState(false)
    }

    return (
        <>
            <Dialog open={state} onClose={handleClose}>
                <DialogTitle>Survey Deactivation request !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to Deactivate the selected survey ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUnlive} autoFocus> Agree</Button>
                    <Button onClick={handleClose}>Disagree</Button>
                </DialogActions>
            </Dialog>


            <SnackBarUI state={snack} setState={setSnack} status={surveyStatus} message={surveyMsg} />
        </>

    )
}


UnliveSurvey.propTypes = {
    survey_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any
};