import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import SnackBarUI from "../../comman/SnackBarUI";
import { addManegerDetailsAction } from "../../redux/toolkit/publisher/addManegerDetails";
import { getPublisherAction } from "../../redux/toolkit/publisher/getPublisher";

// schema
const catSchema = yup
  .object({
    m_name: yup.string().required("Name is required."),
    m_email: yup.string().required("Email is required."),
    m_mobile: yup.string().required("Mobile is required."),
  })
  .required();

export default function ManegerDetails({
  publisherDetails,
  publisher_id,
  state,
  setState,
}) {
  // state
  const {
    register,
    handleSubmit,
    formState: { errors },
 
  } = useForm({ resolver: yupResolver(catSchema) });
  const dispatch = useDispatch();

  const managerInfo = useSelector((state) => state.addManegerDetails);
  const { status, message, loading } = managerInfo;

  const [snack, setSnack] = useState(false);
  console.log("mess", message);

  const handleManegerDetails = async (data) => {
    const addManegerDetails = await dispatch(
      addManegerDetailsAction({ publisher_id, ...data })
    );
    setSnack(true);
    if (addManegerDetails?.payload?.status == 200) {
      await dispatch(getPublisherAction());
   
   
    }
    setState(false)
  };
  console.log(snack);
  const handleClose = function () {
    setState(false);
  };

  return (
    <>
      <Modal sx={{ overflow: "scroll" }} open={state} onClose={handleClose}>
        <Box className="modal_box">
          <Card sx={{ width: 400, p: 5 }}>
            <Typography variant="h5" sx={{ mb: 5 }} align="center">
              Create FAQ
            </Typography>
            <form onSubmit={handleSubmit(handleManegerDetails)}>
              <Stack spacing={3}>
                <TextField
                  type="text"
                  defaultValue={publisherDetails?.m_name}
                  label="Maneger Name"
                  variant="outlined"
                  placeholder="Title"
                  {...register("m_name")}
                  error={errors?.m_name?.message}
                  helperText={errors?.m_name?.message}
                />
                <TextField
                  type="text"
                  defaultValue={publisherDetails?.m_email}
                  label="Maneger Email"
                  variant="outlined"
                  placeholder="Title"
                  {...register("m_email")}
                  error={errors?.m_email?.message}
                  helperText={errors?.m_email?.message}
                />
                <TextField
                  type="text"
                  defaultValue={publisherDetails?.m_mobile}
                  label="Maneger Mobile Number"
                  variant="outlined"
                  placeholder="Title"
                  {...register("m_mobile")}
                  error={errors?.m_mobile?.message}
                  helperText={errors?.m_mobile?.message}
                />

                <Stack direction="row" spacing={2}>
                  <Button variant="contained" type="submit" disabled={loading}>
                    {loading ? <CircularProgress /> : "Create FAQ"}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Card>
        </Box>
      </Modal>
      <SnackBarUI
        state={snack}
        setState={setSnack}
        status={status}
        message={message}
      />
    </>
  );
}

ManegerDetails.propTypes = {
  state: PropTypes.any,
  setState: PropTypes.any,
  publisher_id: PropTypes.any,
  publisherDetails: PropTypes.any,
};
