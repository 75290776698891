import { Autocomplete, Box, Button, Card, Modal, Stack, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingUI from '../../comman/LoadingUI';
import SnackBarUI from '../../comman/SnackBarUI';
import { addPermissionAction } from '../../redux/toolkit/admin/addPermission';
import { getAdminSupportAction } from '../../redux/toolkit/admin/getAdminSupports';


export default function AddPermission({ state, setState, admin_id }) {
    // state
    const dispatch = useDispatch()
    const addPermissionSelector = useSelector(state => state.addPermission)
    const { status, message } = addPermissionSelector

    const getSingleAdminSupportSelector = useSelector(state => state.getSingleAdminSupport)
    const { data, loading } = getSingleAdminSupportSelector

    const [permission, setPermission] = useState([])
    const [snack, setSnack] = useState(false)
    const permissionArr = useState([
        'category',
        'sub_category',
        'qns_and_ans',
        'survey',
        'analytics',
        'menu',
        'publisher',
        'postback',
        'payments'
    ])[0]


    const superAdminId = JSON.parse(localStorage.getItem("ss_active_usr"))?._id

    // fn
    const handleClose = function () {
        setState(false)
    }

    const handlePermission = async (event) => {
        event.preventDefault()
        const addPermissionData = await dispatch(addPermissionAction({ superAdminId: superAdminId, admin_id: admin_id, permission: permission }))
        if (addPermissionData?.payload?.status == 200) {
            await dispatch(getAdminSupportAction())
            setSnack(true)
        }
        setSnack(true)
        setState(false)
    }

    // useEffect


    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <form onSubmit={handlePermission}>
                    <Box className='modal_box'>
                        <Card sx={{ width: 400, p: 5 }}>
                            <Typography variant='h5' sx={{ mb: 5 }} align='center'>Add Role</Typography>

                            <Stack spacing={2}>
                                {loading
                                    ? <LoadingUI />
                                    : <Autocomplete
                                        disablePortal
                                        multiple
                                        aria-multiline
                                        options={permissionArr}
                                        defaultValue={data?.permission}
                                        onChange={(event, value) => setPermission(value)}
                                        renderInput={(params) => <TextField value={permission}
                                            {...params} label="Add Permission" />}
                                    />}
                                <Stack spacing={2} direction={'row'}>
                                    <Button variant='contained' type='submit'>Save</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                                </Stack>
                            </Stack>
                        </Card>
                    </Box>
                </form>
            </Modal>
            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}

AddPermission.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any,
    admin_id: PropTypes.any,
};