import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import SnackBarUI from "../../comman/SnackBarUI";
// import { getAllQuestionAnswerAction } from '../../redux/toolkit/question-answer/getAllQuestionAnswer';
// import { updateQuestionAnswerAction } from '../../redux/toolkit/question-answer/updateQuestionAnswer';
import PropTypes from "prop-types";
import { getSingleQuestionAnswerAction } from "../../redux/toolkit/question-answer/getSingleQuestionAnswer";
import { updateAnswerOptionAction } from "../../redux/toolkit/question-answer/updateAnswerOption";

// schema
const qns_ans_schema = yup.object().shape({
  answer_options: yup
    .array()
    .of(yup.string().required("Answer option is required")),
});

export default function UpdateAnswer({ qns_id, state, setState }) {
  // state

  const dispatch = useDispatch();
  const updateQns = useSelector((state) => state.updateAnswerOption);
  const { status, message, loading } = updateQns;
  const getQns = useSelector((state) => state.getSingleQuestionAnswer);
  const { data: question } = getQns;

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(qns_ans_schema),
    defaultValues: {
      answer_options: question?.ans_options || [],
    },
  });

  const [snack, setSnack] = useState(false);

  // fn
  const handleQns = async (data) => {
    await dispatch(
      updateAnswerOptionAction({
        qns_id: qns_id,
        answer_options: data?.answer_options,
      })
    );
    setSnack(true)
    setState(false)
  };

  const handleClose = function () {
    setState(false);
  };

  useEffect(() => {
    dispatch(getSingleQuestionAnswerAction(qns_id));
  }, [dispatch, state]);

  useEffect(() => {
    let defaultValues = {};
    defaultValues.answer_options = question?.ans_options;
    reset({ ...defaultValues });
  }, [question]);

  return (
    <>
      <Modal sx={{ overflow: "scroll" }} open={state} onClose={handleClose}>
        <Box className="modal_box">
          <Card sx={{ width: 500, p: 5 }}>
            <Typography variant="h5" sx={{ mb: 5 }} align="center">
              Update Question Answer
            </Typography>
            <form onSubmit={handleSubmit(handleQns)}>
              <Stack spacing={3}>
                {question?.ans_options?.map((val, i) => (
                  <TextField
                    key={i + 1}
                    defaultValue={val}
                    {...register(`answer_options[${i}]`)}
                    error={errors?.answer_options?.[i]?.message}
                    helperText={errors?.answer_options?.[i]?.message}
                  />
                ))}

                <Stack direction="row" spacing={2}>
                  <Button variant="contained" type="submit" disabled={loading}>
                    {loading ? <CircularProgress /> : "Update Options"}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Card>
        </Box>
      </Modal>

      <SnackBarUI
        state={snack}
        setState={setSnack}
        status={status}
        message={message}
      />
      <DevTool control={control} />
    </>
  );
}

UpdateAnswer.propTypes = {
  qns_id: PropTypes.string,
  state: PropTypes.any,
  setState: PropTypes.any,
};
