import { yupResolver } from '@hookform/resolvers/yup'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Button, Card, InputAdornment, Link, Paper, Stack, TextField, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from "yup"
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import SnackBarUI from '../../comman/SnackBarUI'
import Appbar from '../../components/Appbar'
import DrawerUI from '../../components/Drawer'
import { dateConvert } from '../../helper/dateConverter'
import Body from '../../layout/Body'
import { getPasuedSurveyAction } from '../../redux/toolkit/survey/getPasuedSurvey'
import { liveSurveyInBulkAction } from '../../redux/toolkit/survey/liveSurveyInBulk'
import { useStyles } from '../../theme/theme'



const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/survey'>Survey</Link>
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/survey/live-bulk-survey'>Live Bulk Survey</Link>
        </Breadcrumbs >
        <Typography variant='h5'>Survey</Typography>
    </Stack >
)

// schema
const surveyDetailsSchema = yup.object({
    // hours: yup.string().required(),
    minutes: yup.number().required(),
}).required();



export default function LiveBulkSurvey() {
    // state
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const getPasuedSurveySelector = useSelector(state => state.getPasuedSurvey)
    const { data: surveyData, loading: surveyLoading } = getPasuedSurveySelector

    const liveSurveyInBulk = useSelector(state => state.liveSurveyInBulk)
    const { status, message } = liveSurveyInBulk

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(surveyDetailsSchema), defaultValues: {}
    });

    const [pageSize, setPageSize] = useState(25);
    const classes = useStyles();
    const [snack, setSnack] = useState(false)
    const [date, setDate] = useState({ start: null, end: null })
    const [surveyList, setSurveyList] = useState([])

    const qnsDataGridColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
            flex: 1,
            minWidth: 170,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'cat_id',
            headerName: 'Category Name',
            flex: 1,
            minWidth: 170,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.cat_id?.cat_name}</Typography>
                )
            },
        },
        {
            field: 'sub_cat_id',
            headerName: 'Sub Category Name',
            flex: 1,
            minWidth: 170,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.sub_cat_id?.sub_cat_name}</Typography>
                )
            },
        },
        {
            field: 'survey',
            headerName: 'Survey',
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
        },
    ]

    // fn
    let handleSurvey = async (data) => {

        // if (new Date(date?.start).getDate() == new Date(date?.end).getDate()) {
        //     return setError('dateError', { type: 'custom', message: 'Start and End date can not be same' });
        // }

        let liveSurveyInBulk = await dispatch(liveSurveyInBulkAction({
            survey_start_date: dateConvert(date?.start),
            survey_end_date: dateConvert(date?.end),
            survey_ids: surveyList,
            minutes: Number(data?.minutes),
        }))
        if (liveSurveyInBulk?.payload?.status == 200) {
            navigate("/survey")
        }
        setSnack(true)
    }


    // console.log("date --->start", dateConvert(date?.start))
    // console.log("date --->end", dateConvert(date?.end))


    // use effect
    useEffect(() => {
        dispatch(getPasuedSurveyAction())
    }, [dispatch])


    return (
        <>
            <Helmet><title>Quick survey | Live Survey</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            <DrawerUI />
            <Body>

                <Card>
                    <Typography variant='h5' sx={{ p: 2 }} >Live survey for certain date / time period</Typography>


                    <form onSubmit={handleSubmit(handleSurvey)}>
                        <Stack sx={{ p: 2 }} spacing={2}>
                            <Stack>
                                <Typography color='primary'>Each Survey Opening Time</Typography>
                                <Stack direction={'row'} spacing={2} sx={{ my: 2 }}>
                                    {/* <TextField type='number'  {...register('hours')} error={errors?.hours?.message} helperText={errors?.hours?.message} fullWidth label='Hours' InputProps={{ endAdornment: <InputAdornment position="end">Minute</InputAdornment>, inputProps: { min: 0, max: 12 } }} /> */}
                                    <TextField type='number' {...register('minutes')} error={errors?.minutes?.message} helperText={errors?.minutes?.message} fullWidth label='Minuts' InputProps={{ endAdornment: <InputAdornment position="end">Minute</InputAdornment>, inputProps: { min: 0, max: 60 } }} />
                                </Stack>
                            </Stack>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Typography color='primary'>Survey Bulk Date Range</Typography>
                                <Stack direction={'row'} spacing={2}>
                                    <DateTimePicker
                                        label="Live Start Date"
                                        value={date?.start}
                                        onChange={(value) => {
                                            // console.log("value", value)
                                            setDate({ ...date, start: value })
                                        }}
                                        minDate={new Date()}
                                        renderInput={(params) => <TextField fullWidth {...params}
                                            helperText={errors?.dateError?.message}
                                            error={errors?.dateError?.message} />}
                                    />
                                    <DateTimePicker
                                        label="Live End Date"
                                        value={date?.end}
                                        minDate={date?.start}
                                        onChange={(value) => {
                                            setDate({ ...date, end: value })
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params}
                                            error={errors?.dateError?.message}
                                            helperText={errors?.dateError?.message}
                                        />}
                                    />
                                </Stack>
                            </LocalizationProvider>


                        </Stack>

                        <Paper sx={{
                            bgcolor: '#fff',
                            '& .super-app-theme--header': {
                                bgcolor: '#071D45',
                                color: '#fff',
                            },
                        }} elevation={0}>
                            <DataGrid
                                className={classes.root}
                                getRowHeight={() => 'auto'}
                                sx={{ border: 0 }}
                                checkboxSelection
                                autoHeight
                                rows={surveyData}
                                columns={qnsDataGridColumns}
                                getRowId={(row) => row._id}
                                disableSelectionOnClick
                                experimentalFeatures={{ newEditingApi: true }}
                                pagination
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowsPerPageOptions={[25, 50, 100]}
                                disableColumnSelector
                                loading={surveyLoading ? <LoadingUI /> : false}
                                onSelectionModelChange={linkedQns => setSurveyList(linkedQns)}
                                components={{
                                    NoRowsOverlay: () => <NoDataFound />,
                                    Toolbar: GridToolbar,
                                }}

                            />
                        </Paper>

                        <Stack direction={'row'} spacing={2} sx={{ p: 2 }}>
                            <Button type='submit' variant='contained'>Live Survey</Button>
                            <Button variant='contained' color='error' onClick={() => { navigate("/survey") }}>Back</Button>
                        </Stack>
                    </form>

                </Card>
            </Body>

            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>

    )
}
