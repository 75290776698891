import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { createCategoryAction } from '../../redux/toolkit/category/createCategory';
import { getCategoryAction } from '../../redux/toolkit/category/getCategory';



// schema
const catSchema = yup.object({
    cat_name: yup.string().required('Category name is required.'),
    sequence_number: yup.number().positive().required('Category name is required.')
}).required();

export default function CreateCategory({ state, setState }) {
    // state
    const { register, control, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(catSchema) });

    const dispatch = useDispatch()
    const createCat = useSelector(state => state.createCategory)
    const { status, message, loading } = createCat

    const [snack, setSnack] = useState(false)

    // fn
    const handleCategory = async (data) => {
        const createCategoryData = await dispatch(createCategoryAction(data))
        if (createCategoryData?.payload?.status == 201) {
            await dispatch(getCategoryAction({ is_deleted: '' }))
        }
        setSnack(true)
        setState(false)
        reset()
    }

    const handleClose = function () {
        setState(false)
    }


    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 400, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Create Category</Typography>
                        <form onSubmit={handleSubmit(handleCategory)}>
                            <Stack spacing={3}>
                                <TextField type='text' label="Category" variant="outlined" placeholder='Category Name' {...register('cat_name')} error={errors?.cat_name?.message} helperText={errors?.cat_name?.message} />
                                <TextField type='number' InputProps={{ inputProps: { min: 1 } }} label="Sequence" variant="outlined" placeholder='Sequence Number' {...register('sequence_number')} error={errors?.sequence_number?.message} helperText={errors?.sequence_number?.message} />
                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Create Category'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>
            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
            <DevTool control={control} />
        </>
    )
}



CreateCategory.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any
};