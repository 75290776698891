import { createAsyncThunk,createSlice } from "@reduxjs/toolkit"
import authAxios from "../../../config/authAxios"

const initialState = { status: null, loading: false, data: [], error: "", message: "" }

export const noOfTotalLiveSurveyAction = createAsyncThunk(
    'total-survey',
    async (data, { rejectWithValue }) => {
        try {
            const res = await authAxios.get(`/support-server/api/total-survey?s_date=${data?.s_date}&e_date=${data?.e_date}`)
            console.log(data)
            return res?.data
            
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
       
    }
    
);

const noOfTotalSurveyReducer = createSlice({
    name:"total-survey",
    initialState,
    reducers:{},
    extraReducers:
        (builder)=> {
            builder.addCase(noOfTotalLiveSurveyAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(noOfTotalLiveSurveyAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(noOfTotalLiveSurveyAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },
    }
)
export const noOfTotalSurvey = noOfTotalSurveyReducer.reducer