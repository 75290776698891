import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import BarChartIcon from '@mui/icons-material/BarChart'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ForumIcon from '@mui/icons-material/Forum'
import HelpIcon from '@mui/icons-material/Help'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import PaymentIcon from '@mui/icons-material/Payment'
import Person2Icon from '@mui/icons-material/Person2'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import WebStoriesIcon from '@mui/icons-material/WebStories'
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import Logo from "../assets/img/logo.png"
import LoadingUI from '../comman/LoadingUI'
import { ThemeContext } from '../context/ThemeProvider'
import Style from '../css/components/Drawer.module.scss'
import { getPermissionAndRoleAction } from '../redux/toolkit/dashbaord/getPermissionAndRole'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SettingsIcon from '@mui/icons-material/Settings';
import { SupervisedUserCircleOutlined } from '@mui/icons-material'




export default function DrawerUI() {
    // state---->
    const dispatch = useDispatch()
    const permission = useSelector(state => state.getPermissionAndRole)
    const { data: permissionData, loading: permissionLoading } = permission



    const { drawerSilde } = useContext(ThemeContext)
    const location = useLocation()?.pathname

    // const permission = JSON.parse(localStorage.getItem("ss_active_usr"))?.permission
    // const role = JSON.parse(localStorage.getItem("ss_active_usr"))?.role

    const drawerListDashboard = []
    if (permissionData?.permission?.includes("home") || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Home", icon: <DashboardIcon fontSize='small' color={location == "/" ? "primary" : ''} />, path: '/' }) }
    if (permissionData?.permission?.includes('category') || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Category", icon: <BookmarkIcon fontSize='small' color={location == '/category' ? "primary" : ''} />, path: '/category' }) }
    if (permissionData?.permission?.includes("sub_category") || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Sub Category", icon: <BookmarksIcon fontSize='small' color={location == '/sub-category' ? "primary" : ''} />, path: '/sub-category' }) }
    if (permissionData?.permission?.includes("qns_and_ans") || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Q & A", icon: <HelpIcon fontSize='small' color={location == '/qns-ans' ? "primary" : ''} />, path: '/qns-ans' }) }
    if (permissionData?.permission?.includes("survey") || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Survey", icon: <LocalOfferIcon fontSize='small' color={location == '/survey' ? "primary" : ''} />, path: '/survey' }) }
    if (permissionData?.permission?.includes("profile") || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Profile", icon: <Person2Icon fontSize='small' color={location == '/profile' ? "primary" : ''} />, path: '/profile' }) }


    const adminList = [
        { name: "Config Meta", icon: <SettingsIcon fontSize='small' color={location == '/config-meta' ? "primary" : ''} />, path: '/config-meta' },
        { name: "Admin", icon: <AdminPanelSettingsIcon fontSize='small' color={location == '/admin' ? "primary" : ''} />, path: '/admin' },
        { name: "Super Admin Logs", icon: <WebStoriesIcon fontSize='small' color={location == '/super-admin-log' ? "primary" : ''} />, path: '/super-admin-log' },
        { name: "Admin Logs", icon: <SupervisorAccountIcon fontSize='small' color={location == '/admin-log' ? "primary" : ''} />, path: '/admin-log' }
    ]


    const statsList = []
    if (permissionData?.permission?.includes("analytics") || permissionData?.role == 'super_admin') { statsList.push({ name: "Analytics", icon: <BarChartIcon fontSize='small' color={location == '/analytics' ? "primary" : ''} />, path: '/analytics' }) }
    if (permissionData?.permission?.includes("menu") || permissionData?.role == 'super_admin') { statsList.push({ name: "Menu", icon: <MenuOpenIcon fontSize='small' color={location == '/menu' ? "primary" : ''} />, path: '/menu' }) }




    const publisherList = []
    if (permissionData?.permission?.includes("publisher") || permissionData?.role == 'super_admin') { publisherList.push({ name: "Publisher", icon: <ForumIcon fontSize='small' color={location == '/publisher' ? "primary" : ''} />, path: '/publisher' }) }
    if (permissionData?.permission?.includes("support-knowledge") || permissionData?.role == 'super_admin') { publisherList.push({ name: "Support & Knowledge", icon: <SupportAgentIcon fontSize='small' color={location == '/support-knowledge' ? "primary" : ''} />, path: '/support-knowledge' }) }
    // if (permission?.includes("postback")  ||   permissionData?.role == 'super_admin') { publisherList.push({ name: "Postback", icon: <FastRewindIcon fontSize='small' color={location == '/post-back' ? "primary" : ''} />, path: '/post-back' }) }
    if (permissionData?.permission?.includes("payments") || permissionData?.role == 'super_admin') { publisherList.push({ name: "Payments", icon: <PaymentIcon fontSize='small' color={location == '/payments' ? "primary" : ''} />, path: '/payments' }) }
    if (permissionData?.permission?.includes("payments") || permissionData?.role == 'super_admin') { publisherList.push({ name: "Postback Logs", icon: <PaymentIcon fontSize='small' color={location == '/postback-logs' ? "primary" : ''} />, path: '/postback-logs' }) }

    if (permissionData?.permission?.includes("payments") || permissionData?.role == 'super_admin') { publisherList.push({ name: "Bank Details", icon: <PaymentIcon fontSize='small' color={location == '/bank-details' ? "primary" : ''} />, path: '/bank-details' }) }



const usersList = []
if (permissionData?.permission?.includes("publisher") || permissionData?.role == 'super_admin') { usersList.push({ name: "Users", icon: <SupervisedUserCircleOutlined fontSize='small' color={location == '/users' ? "primary" : ''} />, path: '/users' }) }

    useEffect(() => {
        dispatch(getPermissionAndRoleAction())
    }, [dispatch])



    return (
        <Box className={drawerSilde ? Style.drawerOpen : Style.drawerClose}>

            <Box className={Style.header}>
                <img style={{ minWidth: "10px" }} src={Logo} alt='logo' />
            </Box>

            <Divider />

            {/* dashboard */}
            <List disablePadding subheader={drawerSilde && <ListSubheader sx={{ fontSize: 17, }} component="div">DashBoard</ListSubheader>}>
                {drawerListDashboard?.map((items, index) => {
                    return (
                        <>
                            {permissionLoading
                                ? <LoadingUI />
                                : <Link key={index} to={items?.path} style={{ textDecoration: 'none', }}>
                                    <ListItem disablePadding className={location == items?.path ? Style.actvie_link : Style.non_actvie_link}>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                {items?.icon}
                                            </ListItemIcon>
                                            {drawerSilde && <ListItemText primary={items?.name} />}
                                        </ListItemButton>
                                    </ListItem>
                                </Link>}

                        </>
                    )

                })}
            </List>

            {/* Admins */}
            {permissionData?.role == 'super_admin' ?
                <List disablePadding subheader={drawerSilde && <ListSubheader sx={{ fontSize: 17, }} component="div">Admin</ListSubheader>}>
                    {adminList?.map((items, index) => {
                        return (
                            <>
                                {permissionLoading
                                    ? <LoadingUI />
                                    :
                                    <Link key={index} to={items?.path} style={{ textDecoration: 'none', }}>
                                        <ListItem disablePadding className={location == items?.path ? Style.actvie_link : Style.non_actvie_link}>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    {items?.icon}
                                                </ListItemIcon>
                                                {drawerSilde && <ListItemText primary={items?.name} />}
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }
                            </>
                        )
                    })}
                </List>
                : null
            }
{/* users */}

{usersList.length ?
                <List disablePadding subheader={drawerSilde && <ListSubheader sx={{ fontSize: 17, }} component="div">Users</ListSubheader>}>
                    {usersList?.map((items, index) => {
                        return (
                            <>
                                {permissionLoading
                                    ? <LoadingUI />
                                    :
                                    <Link key={index} to={items?.path} style={{ textDecoration: 'none', }}>
                                        <ListItem disablePadding className={location == items?.path ? Style.actvie_link : Style.non_actvie_link}>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    {items?.icon}
                                                </ListItemIcon>
                                                {drawerSilde && <ListItemText primary={items?.name} />}
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }
                            </>
                        )
                    })}
                </List>
                : null
            }
            {/* stats */}
            {statsList?.length ?
                <List disablePadding subheader={drawerSilde && <ListSubheader sx={{ fontSize: 17, }} component="div">Stats</ListSubheader>}>
                    {statsList?.map((items, index) => {
                        return (
                            <>
                                {permissionLoading
                                    ? <LoadingUI />
                                    : <Link key={index} to={items?.path} style={{ textDecoration: 'none', }}>
                                        <ListItem disablePadding className={location == items?.path ? Style.actvie_link : Style.non_actvie_link}>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    {items?.icon}
                                                </ListItemIcon>
                                                {drawerSilde && <ListItemText primary={items?.name} />}
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>}
                            </>

                        )
                    })}
                </List>
                : null
            }


            {/* publisher */}
            {publisherList?.length ?
                <List disablePadding subheader={drawerSilde && <ListSubheader sx={{ fontSize: 17, }} component="div">Publisher</ListSubheader>}>
                    {publisherList?.map((items, index) => {
                        return (
                            <>
                                {permissionLoading
                                    ? <LoadingUI />
                                    : <Link key={index} to={items?.path} style={{ textDecoration: 'none', }}>
                                        <ListItem disablePadding className={location == items?.path ? Style.actvie_link : Style.non_actvie_link}>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    {items?.icon}
                                                </ListItemIcon>
                                                {drawerSilde && <ListItemText primary={items?.name} />}
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }

                            </>

                        )
                    })}
                </List>
                : null
            }

        </Box >
    )
}

