import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SnackBarUI from '../../comman/SnackBarUI'
import { getSurveyAction } from '../../redux/toolkit/survey/getSurvey'
import { restoreSurveyAction } from '../../redux/toolkit/survey/restoreSurvey'
import PropTypes from 'prop-types';


export default function RestoreSurvey({ survey_id, state, setState }) {
    // state
    const dispatch = useDispatch()
    const restoreSurvey = useSelector(state => state.restoreSurvey)
    const { status, message } = restoreSurvey

    const [snack, setSnack] = useState(false)

    // fn
    const handleClose = () => {
        setState(false)
    }

    const delCatHandle = async () => {
        await dispatch(restoreSurveyAction(survey_id))
        await dispatch(getSurveyAction({ is_deleted: true }))
        setSnack(true)
        setState(false)
    }

    return (
        <>
            <Dialog open={state} onClose={handleClose}>
                <DialogTitle>Restore item request !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to restore the selected survey ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={delCatHandle} autoFocus> Agree</Button>
                    <Button onClick={handleClose}>Disagree</Button>
                </DialogActions>
            </Dialog>


            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>

    )
}

RestoreSurvey.propTypes = {
    survey_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any
};