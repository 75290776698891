import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAxios from "../../../config/authAxios";

const initialState = {
  status: null,
  loading: false,
  data: [],
  error: "",
  message: "",
};

export const getUserDetailsAction = createAsyncThunk(
  "user-details",
  async (data, { rejectWithValue }) => {
    try {
      const res = await authAxios.get(`support-server/api/get-user-details`);
      return res?.data;
    } catch (error) {
      return rejectWithValue(error?.response.data);
    }
  }
);

const userDetailSlice = createSlice({
    name:"user-details",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getUserDetailsAction.pending,(state)=>{
            state.loading = true
        })
        builder.addCase(getUserDetailsAction.fulfilled, (state, action) => {
            state.loading = false
            state.data = action?.payload?.data
            state.status = action?.payload?.status
            state.message = action?.payload?.message
        })
        builder.addCase(getUserDetailsAction.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.status = action?.payload?.status
            state.message = action?.payload?.message
        })
    }

})
export const userDetailReducer = userDetailSlice.reducer;