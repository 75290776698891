import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SnackBarUI from '../../comman/SnackBarUI'
import { approvedPaymentAction } from '../../redux/toolkit/payments/approvedPayment'
import { paymentRequestsAction } from '../../redux/toolkit/payments/paymentRequests'


export default function ApprovedPayment({ state, setState, payment_id, publisher_id }) {
    // state
    const dispatch = useDispatch()
    const approvedPayment = useSelector(state => state.approvedPayment)
    const { status, message } = approvedPayment

    const [snack, setSnack] = useState(false)

    // fn
    const handleClose = () => {
        setState(false)
    }
console.log("pb",publisher_id)
    const delCatHandle = async () => {
        const deleteSurveyData = await dispatch(approvedPaymentAction({ payment_id: payment_id, publisher_id: publisher_id }))
        if (deleteSurveyData?.payload?.status == 200) {
            await dispatch(paymentRequestsAction({ is_deleted: false }))
        }
        setSnack(true)
        setState(false)
    }

    return (
        <>
            <Dialog open={state} onClose={handleClose}>
                <DialogTitle>Approve Payment !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to approve givien payment ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={delCatHandle} autoFocus>Approve</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>


            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>

    )
}

ApprovedPayment.propTypes = {
    payment_id: PropTypes.string,
    publisher_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any
};
