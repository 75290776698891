// import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingUI from '../../comman/LoadingUI';
import { noOfDeletedSurveyAction } from '../../redux/toolkit/menu/noOfDeletedSurvey';
import {MdOutlineDeleteOutline} from 'react-icons/md'

export default function DeletedSurvey() {
    // state
    const dispatch = useDispatch()
    const deletedSurveySelector = useSelector(state => state.noOfDeletedSurvey)
    const { data, loading } = deletedSurveySelector


    // useeffect
    useEffect(() => {
        dispatch(noOfDeletedSurveyAction())
    }, [dispatch])


    return (
        <Grid item xs={4}>
            <Card sx={{ p: 2,bgcolor:"#071D45" }}>
                <Stack spacing={2} alignItems={'center'}>
                    <Box sx={{ width: 70, height: 70, borderRadius: 1, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <MdOutlineDeleteOutline style={{
                            fontSize:"4rem"
                        }}/>
                    </Box>
                    <Typography variant='subtitle2' color='#fff'>Deleted Survey</Typography>
                    {loading ? <LoadingUI /> : null}
                    <Typography variant='h3' color='#fff'>{data || "--"}</Typography>
                </Stack>
            </Card>
        </Grid>
    )
}
