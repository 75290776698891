import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import authAxios from "../../config/authAxios";
import { useDispatch } from "react-redux";
import { getSurveyAction } from "../../redux/toolkit/survey/getSurvey";

const PauseSurvey = ({ open, close, survey_id }) => {
  const dispatch = useDispatch();
  const handlePauseSurvey = async () => {
    try {
      const config = {
        "Content-Type": "application/json",
      };
      const { data } = await authAxios.patch(
        `/support-server/api/pause-survey`,
        { survey_id },
        config
      );
      if (data?.status === 200) {
        close(false);
        dispatch(getSurveyAction({ is_deleted: false }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    close(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Pause Survey</DialogTitle>
      <DialogContent>
        <DialogContentText>Do You Want To Pause The Survet ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePauseSurvey}>Pause</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

PauseSurvey.propTypes = {
  open: PropTypes.any,
  close: PropTypes.func,
  survey_id: PropTypes.any,
};

export default PauseSurvey;
