import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { uplodImageAction } from "../../redux/toolkit/image/uplodImage";
import { uploadSurveyPicAction } from "../../redux/toolkit/survey/uploadSurveyPic";
import SnackBarUI from "../../comman/SnackBarUI";
import LoadingUI from "../../comman/LoadingUI";
const UploadSurveyPic = ({ state, setState, survey_id }) => {
  const dispatch = useDispatch();
  console.log(survey_id);
  const [imageUrl, setImageUrl] = useState("");
  const [file, setFile] = useState("");
  const [snake, setSnake] = useState(false);
  console.log(imageUrl);
  const { status, message } = useSelector((state) => state.uploadSurvey);
  const [loading, setLoading] = useState(false);
  const handleUploadPic = async () => {
    if (!file) {
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.set("file", file);
    const uploadPic = await dispatch(uplodImageAction(formData));

    await dispatch(
      uploadSurveyPicAction({
        imageUrl: uploadPic?.payload?.data?.url,
        survey_id: survey_id,
      })
    );

    setSnake(true);
    setLoading(false);
  };

  return (
    <>
      <Dialog fullWidth open={state} onClose={() => setState(false)}>
        <DialogTitle>Upload Survey Pic</DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              position: "relative",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"center"}
              gap={2}
              alignItems={"center"}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                color: "white",
                fontSize: "16px",
                background: "#8ebf42",
                borderRadius: 1,
                paddingBlock: 1,
              }}
            >
              Upload File <CloudUploadOutlinedIcon />
            </Stack>
            <TextField
              sx={{
                opacity: 0,
                zIndex: 10000,
              }}
              fullWidth
              type="file"
              onChange={(e) => {
                const imageFile = e.target.files[0];

                if (imageFile) {
                  setFile(imageFile);

                  const imageUrl = URL.createObjectURL(imageFile);
                  setImageUrl(imageUrl);
                }
              }}
            />
          </Stack>
          {imageUrl ? (
            <Stack
              sx={{
                border: "1px solid black",
                borderRadius: 0.4,
                height: 400,
                width: "100%",
                position: "relative",
              }}
              direction={"column"}
              alignItems={"center"}
              gap={2}
            >
              <Typography>Image Preview</Typography>
              <img
                style={{
                  objectFit: "contain",
                  width: "85%",
                  height: "85%",
                  position: "absolute",
                  top: 50,
                }}
                src={imageUrl}
                alt="surveyPic"
              />
            </Stack>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled ={loading} variant="contained" onClick={handleUploadPic}>
            Upload
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => setState(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Loader */}
      <Dialog open = {loading}>
        <DialogContent>
          <Typography>Uploading Please Wait..</Typography>
          <LoadingUI />
        </DialogContent>
      </Dialog>
      <SnackBarUI
        message={message}
        state={snake}
        status={status}
        setState={setSnake}
      />
    </>
  );
};
UploadSurveyPic.propTypes = {
  state: PropTypes.string,
  setState: PropTypes.any,
  survey_id: PropTypes.any,
};
export default UploadSurveyPic;
