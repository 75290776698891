import { Box } from '@mui/material';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            labels: {
                color: "#000",
                font: { size: 14,family:'Montserrat'},
            },
            position: 'right'
        }
    },
    cutout: 70
}


export function Graph_PublisherFromDeffPlatform() {

    // state
    const diffentPlatformPublisherSelector = useSelector(state => state.diffentPlatformPublisher)
    const { data } = diffentPlatformPublisherSelector

    const graphData = {
        labels: data?.map(item => item?._id),
        datasets: [
            {
                data: data?.map(item => item?.total_publisher),
                backgroundColor: [
                    'rgba(255, 99, 132)',
                    'rgba(54, 162, 235)',
                    'rgba(255, 206, 86)',
                    'rgba(75, 192, 192)',
                    'rgba(153, 102, 255)',
                    'rgba(255, 159, 64)',
                ],
                borderWidth: 0,
            },
        ],
    };

    return (
        <Box sx={{ height: 200, }}>
            <Doughnut data={graphData} height="100%" width="100%" options={options} />
        </Box>
    )
}
