import FilterAltIcon from '@mui/icons-material/FilterAlt'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, Card, Grid, IconButton, Link, Stack, TextField, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import Appbar from '../../components/Appbar'
import DrawerUI from '../../components/Drawer'
import { dateConvert } from '../../helper/dateConverter'
import Body from '../../layout/Body'
import { getAnalyticsSurveyDataAction } from '../../redux/toolkit/analytics/getAnalyticsSurveyData'
import NoOfParticipent from './NoOfParticipent'
import NoOfQualifiedSurvey from './NoOfQualifiedSurvey'
import NoOfSurveyAttempts from './NoOfSurveyAttempts'

import UserCount from './UserCount'

const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/analytics'>Analytics</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Analytics</Typography>
    </Stack>
)



export default function Analytics() {
    // state 
    const getAnalyticsSurveyDataSelector = useSelector(state => state.getAnalyticsSurveyData)
    const { data, loading } = getAnalyticsSurveyDataSelector

    const dispatch = useDispatch()
    const [date, setDate] = useState({
        start: null,
        end: null,
    })
console.log(data,"analytics")
    // fn
    const handleFilterData = () => {
        // dispatch(noOfDropedSurveyAction({ s_date: date?.start, e_date: date?.end }))
        // dispatch(noOfParticipentAction({ s_date: date?.start, e_date: date?.end }))
        // dispatch(noOfQualifiedSurveyAction({ s_date: date?.start, e_date: date?.end }))
        // dispatch(noOfSurveyAttemptsAction({ s_date: date?.start, e_date: date?.end }))
        // dispatch(noOfSurveyImpressionGenratedAction({ s_date: date?.start, e_date: date?.end }))
        dispatch(getAnalyticsSurveyDataAction({ s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
    }


    // useeffect
    useEffect(() => {
        // dispatch(noOfParticipentAction())
        // dispatch(noOfQualifiedSurveyAction())
        // dispatch(noOfSurveyAttemptsAction())
        // dispatch(noOfSurveyImpressionGenratedAction())
        dispatch(getAnalyticsSurveyDataAction())
    }, [dispatch])


    return (
        <>
            <Helmet>
                <title>Quick Survey | analytics</title>
            </Helmet>


            <Appbar breadcrumb={breadcrumb} />
            <DrawerUI />
            <Body>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Card sx={{ p: 2 }}>
                        <Typography color='#071D45'>Filter by Date</Typography>
                        <Stack direction={'row'} spacing={2} sx={{ mt: 1 }} alignItems='center'>
                            <DatePicker
                                label="Start Date"
                                value={date?.start}
                                onChange={(value) => setDate({ ...date, start: value })}
                                renderInput={(params) => <TextField {...params} />}
                                maxDate={date?.end}

                            />
                            <DatePicker
                                label="End Date"
                                value={date?.end}
                                onChange={(value) => setDate({ ...date, end: value })}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={date?.start}
                            />
                            <Box>
                                <IconButton disabled={!date?.start || !date?.end} onClick={handleFilterData} color='primary'><FilterAltIcon /></IconButton>
                            </Box>
                        </Stack>
                    </Card>
                </LocalizationProvider>


                <Grid container spacing={2} sx={{ my: 2 }}>

                    <NoOfSurveyAttempts loading={loading} data={data?.no_of_survey_atempts} />
                    <NoOfParticipent loading={loading} data={data?.no_of_survey_participent} />
                    <NoOfQualifiedSurvey loading={loading} data={data?.no_of_survey_completed} />
                    
                    <UserCount loading={loading} data={data?.no_of_user_counts}/>

                </Grid>

            </Body>
        </>
    )
}
