import CachedIcon from '@mui/icons-material/Cached';
import { Box, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingUI from '../../comman/LoadingUI';
import { totalRevenueAction } from '../../redux/toolkit/dashbaord/totalRevenue';
import { userDayWiseAction } from '../../redux/toolkit/dashbaord/userDayWise';
import { toPublisherAction } from '../../redux/toolkit/dashbaord/toPublisher';
import { Graph_Dashbaord } from './Graph_Dashbaord';




export default function Dashboard() {
    // state
    const dispatch = useDispatch()

    const totalRevenueSelector = useSelector(state => state.totalRevenue)
    const { data: totalRevenueData, loading: totalRevenueLoading } = totalRevenueSelector


    // fn
    const handleRefresh = () => {
        dispatch(totalRevenueAction())
        dispatch(toPublisherAction())
        dispatch(userDayWiseAction())
    }


    // useEffect
    useEffect(() => {
        dispatch(totalRevenueAction())
        dispatch(toPublisherAction())
        dispatch(userDayWiseAction())
    }, [dispatch])


    return (
        <Card sx={{ p: 2, height: 350 }}>
            <IconButton sx={{ mb: 1 }} onClick={handleRefresh}><CachedIcon /></IconButton>
            <Grid container>
                <Grid item xs={4}>
                    <Stack spacing={2}>
                        <Box>
                            <Typography variant='h6' sx={{ color:'#071D45',fontFamily:'Montserrat'}}>Total Revenue</Typography>
                            {totalRevenueLoading ? <LoadingUI justifyContent='flex-start' /> : null}
                            {totalRevenueData ? <Typography color='#071D45' variant='h3'>{totalRevenueData}</Typography> :
                                <Typography color='#071D45' variant='h3'>--</Typography>
                            }
                        </Box>
                    </Stack>
                </Grid>


                <Grid item xs={8}>
                    <Typography variant='h6'>Total Users Per Day</Typography>
                    <Graph_Dashbaord />
                </Grid>
            </Grid>
        </Card >
    )
}
