import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { getAllQuestionAnswerAction } from '../../redux/toolkit/question-answer/getAllQuestionAnswer';
import { updateQuestionAnswerAction } from '../../redux/toolkit/question-answer/updateQuestionAnswer';
import PropTypes from 'prop-types';

// schema
const qns_ans_schema = yup.object({
    question: yup.string().required('Question is required.'),
}).required();


export default function UpdateQnsAns({ qns_id, state, setState }) {
    // state
    const dispatch = useDispatch()
    const updateQns = useSelector(state => state.updateQuestionAnswer)
    const { status, message, loading } = updateQns
    const getQns = useSelector(state => state.getSingleQuestionAnswer)
    const { data: { question } } = getQns


    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(qns_ans_schema),
        defaultValues: {
            question: question
        }
    });


    const [snack, setSnack] = useState(false)

    // fn
    const handleQns = async (data) => {
        await dispatch(updateQuestionAnswerAction({ qns_id: qns_id, ...data }))
        await dispatch(getAllQuestionAnswerAction({ is_deleted: false }))
        setSnack(true)
        setState(false)
    }

    const handleClose = function () {
        setState(false)
    }


    useEffect(() => {
        let defaultValues = {};
        defaultValues.question = question;
        reset({ ...defaultValues });
    }, [question])





    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 500, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Update Question Answer</Typography>
                        <form onSubmit={handleSubmit(handleQns)}>
                            <Stack spacing={3}>
                                {question && <TextField defaultValue={question} type='text' label="Question" variant="outlined" placeholder='Question' {...register('question')} error={errors?.question?.message} helperText={errors?.question?.message} />}

                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Update Q & A'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>

            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
            <DevTool control={control} />
        </>
    )
}


UpdateQnsAns.propTypes = {
    qns_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any
};
