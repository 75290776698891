import { Button, Card, Stack, Typography } from '@mui/material'
import React from 'react'

const Block = () => {
  return (
    <Stack sx={{
        position:"absolute",
        top:"50%",
        left:"50%",
        transform:"translate(-50%,-50%)"

    }}>
        <Card sx={{
            width:"500px",
            paddingBlock:"30px",
            paddingInline:"30px",
            display:"flex",
            justifyContent:"center",
            flexDirection:"column",
            gap:"20px"
        }}>
            
            <Typography sx={{
                textAlign:"center"
            }}>
        You have been blocked by Super Admin, please contact Super Admin
            </Typography>
            <Button variant='contained'>Contact Admin</Button>
        </Card>
    </Stack>
  )
}

export default Block