import { Avatar, Card, Stack, Typography } from '@mui/material'
import React from 'react'

export default function UserInfo() {
    const acitveUserName = JSON.parse(localStorage.getItem('ss_active_usr'))

    return (
        <Card sx={{ pb: 3 }}>
            <Stack spacing={2} alignItems={'center'}>
                <img src='assets/img/porfile_banner.png' />
                <Avatar sx={{ width: 100, height: 100, bgcolor: '#071D45', border: '6px solid #fff' }} style={{ marginTop: '-40px' }}>{acitveUserName?.name?.substring(0, 1).toUpperCase()}</Avatar>
                <Stack spacing={1} alignItems='center'>
                    <Typography variant='h5'>{acitveUserName?.name.toUpperCase()}</Typography>
                    <Typography variant='subtitle2'>{acitveUserName?.role}</Typography>
                </Stack>
            </Stack>
        </Card>
    )
}
