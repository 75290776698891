import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Breadcrumbs,
  Card,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { RiBankFill } from "react-icons/ri";
import React, { useEffect} from "react";
import { Helmet } from "react-helmet-async";

import Appbar from "../../../components/Appbar";
import DrawerUI from "../../../components/Drawer";

import Body from "../../../layout/Body";
import { DataGrid } from "@mui/x-data-grid";
import { useStyles } from "../../../theme/theme";
import { useDispatch, useSelector } from "react-redux";


import { getBankDetailsAction } from "../../../redux/toolkit/publisher/bank-details/getAllbanks";
import { useNavigate } from "react-router-dom";



const breadcrumb = (
  <Stack>
    <Breadcrumbs
      separator={
        <NavigateNextIcon sx={{ color: "#5E6C8A" }} fontSize="small" />
      }
      aria-label="breadcrumb"
    >
      <Link
        underline="hover"
        sx={{ color: "#5E6C8A" }}
        color="inherit"
        href="/"
      >
        Home
      </Link>
      <Link
        underline="hover"
        sx={{ color: "#5E6C8A" }}
        color="inherit"
        href="/analytics"
      >
       Bank Details
      </Link>
    </Breadcrumbs>
    <Typography sx={{ color: "#5E6C8A" }} variant="h5">
      Bank Details{" "}
    </Typography>
  </Stack>
);

export default function BankDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.getBankDetails);
  
  
  // const [userId,setUserId] = useState("")

  useEffect(() => {
    dispatch(getBankDetailsAction());
  }, [dispatch]);
  console.log(data);
const navigate = useNavigate();
  const UserDetailsColumn = [
    {
      field: "s_no", // Change the field name to 's_no'
      headerName: "S. No.",
      filterable: false,
      flex: 1,
      minWidth: 10,
      headerClassName: "super-app-theme--header",
      renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1,
    },
    {
      field: "publisher_id",
      headerName: "Bank Id",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      renderCell:(params)=>{
        return(
          <Link style={{
            cursor:"pointer"
          }} onClick={() => {
            navigate(`/single-bank/${params?.row?._id}`)
             
             
           }}>{params?.row?._id}</Link>
          
        )
      }

    },
    {
      field: "publisherName",
      headerName: "Publisher Name",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "holder_name",
      headerName: "Account Name",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "publisherEmail",
      headerName: "Email Id",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bussiness_name",
      headerName: "Buisness Name",
      flex: 1,
      minWidth: 50,

      headerClassName: "super-app-theme--header",
    },
    
   
    {
      field: "isBlocked",
      headerName: "Verify Bank",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return ( 
          <>
          <Stack direction={"column"}>
          {
           !params?.row?.is_varified && <RiBankFill
            style={{
              fontSize: "2rem",
              cursor: "pointer",
              color: params?.row?.is_varified ? "green" : "red",
            }}
            onClick={() => {
             navigate(`/single-bank/${params?.row?._id}`)
              
              
            }}
          />
          }
         
          <Typography>
{params?.row?.is_varified && "Verified"}
          </Typography>
          </Stack>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Bank Details</title>
      </Helmet>

      <Appbar breadcrumb={breadcrumb} />
      <DrawerUI />
      <Body>
        <Card>
          <Paper
            sx={{
              bgcolor: "#fff",
              "& .super-app-theme--header": {
                bgcolor: "#071D45",
                color: "#fff",
              },
            }}
            elevation={0}
          >
            <DataGrid
              className={classes.root}
              sx={{
                border: 0,
              }}
              pagination
              columns={UserDetailsColumn}
              rows={data}
              getRowId={(row) => row._id}
              autoHeight
              disableSelectionOnClick
              disableColumnSelector
            />
          </Paper>
        </Card>
      </Body>
      
    </>
  );
}
