import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const getUserSurveyDetailsAction = createAsyncThunk(
    'get-user-survey-details',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/support-server/api/get-user-survey-details?survey_id=${data}`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const getUserSurveyDetailsSlice = createSlice({
    name: 'get-user-survey-details',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(getUserSurveyDetailsAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(getUserSurveyDetailsAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(getUserSurveyDetailsAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const getUserSurveyDetailsReducer = getUserSurveyDetailsSlice.reducer    