
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Button, Card, CircularProgress, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingUI from '../../../comman/LoadingUI';
import SnackBarUI from '../../../comman/SnackBarUI';
import Appbar from '../../../components/Appbar';
import DrawerUI from '../../../components/Drawer';
import Body from '../../../layout/Body';
import { editSurveyAction } from '../../../redux/toolkit/survey/editSurvey';
import { getSingleSurveyAction } from '../../../redux/toolkit/survey/getSignleSurvey';
import { getSurveyAction } from '../../../redux/toolkit/survey/getSurvey';

const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/survey'>Survey</Link>
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/survey/edit-survey'>edit Survey</Link>
        </Breadcrumbs >
        <Typography sx={{ color: "#fff" }} variant='h5'>Survey</Typography>
    </Stack >
)



export default function EditSurvey() {

    // state
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const survey_id = searchParams.get('survey_id')

    const createSurvey = useSelector(state => state.editSurvey)
    const { status: editSurveyStatus, message: editSurveyMsg, loading: editSurveyLoading } = createSurvey
    const singleSurvey = useSelector(state => state.getSingleSurvey)
    const { data, loading } = singleSurvey
    const { card_bg_color } = data


    const [snack, setSnack] = useState(false)
    const [cardBgColorVisible1, setCardBgColorVisible1] = useState(false)
    const [cardBgColorVisible2, setCardBgColorVisible2] = useState(false)
    const [cardBgColor, setCardBgColor] = useState({
        gradient1: '',
        gradient2: '',
    })

    // // fn
    const handleEditSurvey = async () => {

        const editSurvey = await dispatch(editSurveyAction({
            survey_id: survey_id,
            card_bg_color: [cardBgColor?.gradient1, cardBgColor?.gradient2],
        }))

        if (editSurvey?.payload?.status == 201) {
            await dispatch(getSurveyAction({ is_deleted: false }))
            setCardBgColorVisible1(false)
            setCardBgColorVisible2(false)
            setSnack(true)
            navigate("/survey")
        }

    }

    const handleChangeCardColor1 = (color) => {
        setCardBgColor({
            ...cardBgColor,
            gradient1: color?.hex,

        })
    }

    const handleChangeCardColor2 = (color) => {
        setCardBgColor({
            ...cardBgColor,
            gradient2: color?.hex,

        })
    }

    const handleClose = function () {
        navigate("/survey")
    }

    // useEffect
    useEffect(() => {
        dispatch(getSingleSurveyAction(survey_id))
        setCardBgColor({
            gradient1: card_bg_color?.[0],
            gradient2: card_bg_color?.[1]
        })
    }, [])



    return (
        <>
            <Helmet><title>Quick survey | Survey edit survey</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            <DrawerUI />
            <Body>
                <Card sx={{ p: 5 }}>
                    <Typography variant='h5' sx={{ mb: 5 }}>Edit Survey</Typography>
                    {loading ? <LoadingUI />
                        : <>

                            <Stack spacing={3}>
                                <Box>
                                    <Typography variant='h6' color='primary'>Survey Card Color</Typography>
                                    <Stack direction='row' spacing={2}>
                                        <Box>
                                            <Button variant='contained'
                                                sx={{ bgcolor: cardBgColor?.gradient1 }}
                                                onClick={() => {
                                                    setCardBgColorVisible1(true);
                                                    setCardBgColorVisible2(false);
                                                }}>Card Gradient 1</Button>
                                        </Box>
                                        <Box>
                                            <Button variant='contained'
                                                sx={{ bgcolor: cardBgColor?.gradient2 }}
                                                onClick={() => {
                                                    setCardBgColorVisible2(true);
                                                    setCardBgColorVisible1(false);
                                                }}>Card Gradient 2</Button>
                                        </Box>

                                    </Stack>
                                    {cardBgColorVisible1 ?
                                        <Box sx={{ mt: 2 }}>
                                            <ChromePicker color={cardBgColor?.gradient1} onChangeComplete={handleChangeCardColor1} />
                                        </Box>
                                        : null
                                    }
                                    {cardBgColorVisible2 ?
                                        <Box sx={{ mt: 2 }}>
                                            <ChromePicker color={cardBgColor?.gradient2} onChangeComplete={handleChangeCardColor2} />
                                        </Box>
                                        : null
                                    }
                                </Box>


                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' onClick={handleEditSurvey} disabled={editSurveyLoading}>{editSurveyLoading ? <CircularProgress /> : 'Edit Survey'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Back</Button>
                                </Stack>
                            </Stack>

                        </>
                    }
                </Card>
            </Body>
            <SnackBarUI state={snack} setState={setSnack} status={editSurveyStatus} message={editSurveyMsg} />
        </>
    )
}


EditSurvey.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any,
    sub_cat_id: PropTypes.any,
    survey_id: PropTypes.string,
    editLinkQns: PropTypes.any,
};