import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Breadcrumbs,
  Card,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import uniqid from "uniqid";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import Appbar from "../../../components/Appbar";
import DrawerUI from "../../../components/Drawer";

import Body from "../../../layout/Body";
import { DataGrid } from "@mui/x-data-grid";
import { useStyles } from "../../../theme/theme";
import { useDispatch, useSelector } from "react-redux";

import { getPostbackLogsAction } from "../../../redux/toolkit/publisher/getPostBack";

const breadcrumb = (
  <Stack>
    <Breadcrumbs
      separator={
        <NavigateNextIcon sx={{ color: "#5E6C8A" }} fontSize="small" />
      }
      aria-label="breadcrumb"
    >
      <Link
        underline="hover"
        sx={{ color: "#5E6C8A" }}
        color="inherit"
        href="/"
      >
        Home
      </Link>
      <Link
        underline="hover"
        sx={{ color: "#5E6C8A" }}
        color="inherit"
        href="/postback-logs"
      >
        Postback Logs
      </Link>
    </Breadcrumbs>
    <Typography sx={{ color: "#5E6C8A" }} variant="h5">
      Postback Logs{" "}
    </Typography>
  </Stack>
);

export default function PostbackLogs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.getPostbackLogs);

  // const [userId,setUserId] = useState("")

  useEffect(() => {
    dispatch(getPostbackLogsAction());
  }, [dispatch]);
  console.log(data);

  const UserDetailsColumn = [
    {
      field: "s_no", // Change the field name to 's_no'
      headerName: "S. No.",
      filterable: false,

      width: 20,
      headerClassName: "super-app-theme--header",
      renderCell: (index) => index?.api?.getRowIndex(index.row) + 1
    },
    {
      field: "publisher_id",
      headerName: "ID",
      minWidth: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "publisherName",
      headerName: "Publisher Name",

      minWidth: 150,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "status",
      headerName: "Status",

      width: 80,
      headerClassName: "super-app-theme--header",
      
    },

    {
      field: "time",
      headerName: "Time",
      minWidth: 180,

      headerClassName: "super-app-theme--header",
    },
    {
      field: "userName",
      headerName: "User Name",
      minWidth: 180,

      headerClassName: "super-app-theme--header",
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 180,

      headerClassName: "super-app-theme--header",
    },
    {
      field: "url",
      headerName: "URL",
      minWidth:1500,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return <Typography sx={{
          width:"200px"
        }}>{ params.row.url}</Typography>
      },
    }
    
    // {
    //     field: "time",
    //     headerName: "Time",
    //     flex: 1,
    //     minWidth: 50,

    //     headerClassName: "super-app-theme--header",
    //     renderCell: (params) => {
    //       return params?.row?.logs?.time;
    //     },
    //   },
  ];

  return (
    <>
      <Helmet>
        <title>Bank Details</title>
      </Helmet>

      <Appbar breadcrumb={breadcrumb} />
      <DrawerUI />
      <Body>
        <Card>
          <Paper
            sx={{
              bgcolor: "#fff",
              "& .super-app-theme--header": {
                bgcolor: "#071D45",
                color: "#fff",
              },
              overflow:"auto"
            }}
            elevation={0}
          >
            <DataGrid
             
              className={classes.root}
              sx={{
                border: 0,
               
              }}
              columnVisibilityModel
              pagination
              columns={UserDetailsColumn}
              rows={data}
              getRowId={uniqid}
              autoHeight
              disableSelectionOnClick
              disableColumnSelector
            />
          </Paper>
        </Card>
      </Body>
    </>
  );
}
