import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }



export const diffentPlatformPublisherAction = createAsyncThunk(
    'diff-platform-publisher',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/support-server/api/diff-platform-publisher`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const diffentPlatformPublisherSlice = createSlice({
    name: 'diff-platform-publisher',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(diffentPlatformPublisherAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(diffentPlatformPublisherAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(diffentPlatformPublisherAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const diffentPlatformPublisherReducer = diffentPlatformPublisherSlice.reducer
