import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';



ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
        x: {
            // grid: {
            //     display: false
            // },
            ticks: {
                precision: 0,
                stepSize: 1
            }
        },
        y: {
            // grid: {
            //     display: false
            // },
            ticks: {
                precision: 0,
                stepSize: 1
            }
        }
    }
};



export function Graph_Dashbaord() {
    // state
    const userDayWiseSelector = useSelector(state => state.userDayWise)
    const { data } = userDayWiseSelector


    const graphData = {
        labels: data?.map(item => item?._id),
        datasets: [
            {
                fill: true,
                label: 'Users',
                data: data.map((items) => items?.user_count),
                backgroundColor: '#071D45',
                borderColor: '#071D45',
            },
        ],
    };

    return (
        <Line options={options} data={graphData} width='36%' height='12%' />
    )
}