import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Breadcrumbs,
  Button,
  Card,


  Dialog,


  DialogActions,


  DialogContent,


  DialogContentText,


  DialogTitle,


  Link,

  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { TbLockSquareRoundedFilled } from "react-icons/tb";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import Appbar from "../../components/Appbar";
import DrawerUI from "../../components/Drawer";

import Body from "../../layout/Body";
import { DataGrid } from "@mui/x-data-grid";
import { useStyles } from "../../theme/theme";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsAction } from "../../redux/toolkit/users/userDetails";
import BlockUser from "./BlockUser";

const breadcrumb = (
  <Stack>
    <Breadcrumbs
      separator={
        <NavigateNextIcon sx={{ color: "#5E6C8A" }} fontSize="small" />
      }
      aria-label="breadcrumb"
    >
      <Link
        underline="hover"
        sx={{ color: "#5E6C8A" }}
        color="inherit"
        href="/"
      >
        Home
      </Link>
      <Link
        underline="hover"
        sx={{ color: "#5E6C8A" }}
        color="inherit"
        href="/analytics"
      >
        Users
      </Link>
    </Breadcrumbs>
    <Typography sx={{ color: "#5E6C8A" }} variant="h5">
      Users{" "}
    </Typography>
  </Stack>
);

export default function Users() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.usersDetail);
  const [openBlockUser, setOpenBlockUser] = useState(false);
  const[isBlocked,setIsBlocked] = useState(false)
  const [userId,setUserId] = useState("")
  const[userData,setUserdata] = useState({})
  const[openModal,setOpenModal] = useState(false)

  useEffect(() => {
    dispatch(getUserDetailsAction());
  }, [dispatch]);
  console.log(data);

  const UserDetailsColumn = [
    {
      field: "s_no", // Change the field name to 's_no'
      headerName: "S. No.",
      filterable: false,
      flex: 1,
      minWidth: 10,
      headerClassName: "super-app-theme--header",
      renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1,
    },
    {
      field: "_id",
      headerName: "User Id",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      renderCell:(params)=>{
       return (
        <Link onClick={()=>{
          setOpenModal(true)
          setUserdata(params.row)
        }} >{params?.row?._id}</Link>
        
       )
      }
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email Id",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "platform",
      headerName: "Platform",
      flex: 1,
      minWidth: 50,

      headerClassName: "super-app-theme--header",
    },
    {
      field: "survey_complete",
      headerName: "Survey Done",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "publisherName",
      headerName: "Publisher Name",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "isBlocked",
      headerName: "Block",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <TbLockSquareRoundedFilled
            style={{
              fontSize: "2rem",
              cursor: "pointer",
              color: params?.row?.isBlocked ? "red" : "green",
            }}
            onClick={() => {
              setOpenBlockUser(true);
              setUserId(params?.row?._id)
              setIsBlocked(params?.row?.isBlocked)
            }}
          />
        );
      },
    },
  ];
console.log(userData)
  return (
    <>
      <Helmet>
        <title>Users Details</title>
      </Helmet>

      <Appbar breadcrumb={breadcrumb} />
      <DrawerUI />
      <Body>
        <Card>
          <Paper
            sx={{
              bgcolor: "#fff",
              "& .super-app-theme--header": {
                bgcolor: "#071D45",
                color: "#fff",
              },
            }}
            elevation={0}
          >
            <DataGrid
              className={classes.root}
              sx={{
                border: 0,
              }}
              pagination
              columns={UserDetailsColumn}
              rows={data}
              getRowId={(row) => row._id}
              autoHeight
              disableSelectionOnClick
              disableColumnSelector
            />
          </Paper>
        </Card>
      </Body>
      <BlockUser open ={openBlockUser} close = {setOpenBlockUser} isBlocked={isBlocked} userId = {userId} />
    <Dialog open={openModal} onClose={()=>setOpenModal(false)} fullWidth>
      <DialogTitle>User Details</DialogTitle>
      <DialogContent>
       <DialogContentText>
        <Typography sx={{
          fontWeight:600
        }}>Email : {userData?.email}</Typography>
      <Typography sx={{
          fontWeight:600
        }}>Age : {userData?.age}</Typography>
        <Typography sx={{
          fontWeight:600
        }}>Gender : {userData?.gender}</Typography>
        <Typography sx={{
          fontWeight:600
        }}>Employment Status : {userData?.employment_status}</Typography>
         <Typography sx={{
          fontWeight:600
        }}>Marital Status : {userData?.materal_status}</Typography>
        <Typography sx={{
          fontWeight:600
        }}>Family Members : {userData?.family_member}</Typography>
        <Typography sx={{
          fontWeight:600
        }}>Household Income : {userData?.houshold_encome}</Typography>
            <Typography sx={{
          fontWeight:600
        }}>Earning : {userData?.reward} INR </Typography>
        <Typography sx={{
          fontWeight:600
        }}>City : {userData?.city} </Typography>

       </DialogContentText>
       
      </DialogContent>
      <DialogActions><Button variant="contained" onClick={()=>setOpenModal(false)}>Ok</Button></DialogActions>
    </Dialog>
    </>
  );
}
