import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Autocomplete, Box, Button, Card, Chip, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { createQuestionAnswerAction } from '../../redux/toolkit/question-answer/createQuestionAnswer';
import { getAllQuestionAnswerAction } from '../../redux/toolkit/question-answer/getAllQuestionAnswer';
import { getSubCategoryAction } from '../../redux/toolkit/sub-category/getSubCategory';

let cat_id = ''
let sub_cat_id = ''


// schema
const qns_ans_schema = yup.object({
  cat_name: yup.string().required('Category is required.'),
  sub_cat_name: yup.string().required('Sub category is required.'),
  question: yup.string().required('Question is required.'),
  answer_type: yup.string().required('Answer type is required.'),
}).required();


export default function CreateQuestionAnswer({ state, setState }) {
  // state
  const { register, setError, watch, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(qns_ans_schema) });
  const answer_type = watch("answer_type");

  const dispatch = useDispatch()
  const createQns = useSelector(state => state.createQuestionAnswer)
  let { status, message, loading } = createQns
  const getCat = useSelector(state => state.getCategory)
  const { data: getCatData } = getCat

  const getSubCat = useSelector(state => state.getSubCategory)
  const { data: getSubCatData } = getSubCat

  const [snack, setSnack] = useState(false)
  const [ansArr, setAnsArr] = useState([])
  const [answerOption, setAnswerOption] = useState('')


  // fn
  const handleQns = async (data) => {
    if (!ansArr.length || ansArr.length == 1) {
      return setError('ansOps', { type: 'custom', message: 'Answer option can not be empty. and length must be atleast 2.' });
    }

    const createQnsDataAction = await dispatch(createQuestionAnswerAction({ ans_options: ansArr, cat_id: cat_id?._id, sub_cat_id: sub_cat_id?._id, ...data }))
    if (createQnsDataAction?.payload?.status == 201) {
      await dispatch(getAllQuestionAnswerAction({ is_deleted: false }))
    }
    setAnsArr([])
    setSnack(true)
    setState(false)
    reset()
  }


  const handleClose = function () {
    setState(false)
  }

  const handleAnswerType = (event, value) => {
    if (value == "optional" || value == "MCQ") {
      setAnsArr([])
    }
  }

  const handleAddAnsOpt = () => {
    const foundDuplicate = ansArr.find(element => element == answerOption);
   if(foundDuplicate){
   setError('ansOps', { type: 'custom', message: 'Answer option can not be same.' });
   }
    if (foundDuplicate !== undefined) return 
    setError('ansOps', { type: 'custom', message: '' });
    setAnsArr(prev => [...prev, answerOption])
    setAnswerOption('')
  }


  const handleDelAnsOpt = (i) => {
    setAnsArr((current) => current.filter((chips, index) => { return index !== i; }))
    setAnswerOption('')
  }

  const handleAnsChange = (event, value) => {
    if (value == 'AgreeDisagree') setAnsArr(['Agree', 'Disagree'])
    else if (value == 'TrueFalse') setAnsArr(['True', 'False'])
    else if (value == 'YesNo') setAnsArr(['Yes', 'No'])
  }


  return (
    <>
      <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
        <Box className='modal_box'>
          <Card sx={{ width: 500, p: 5 }}>
            <Typography variant='h5' sx={{ mb: 5 }} align='center'>Create Question Answer</Typography>
            <form onSubmit={handleSubmit(handleQns)}>
              <Stack spacing={3}>


                <Autocomplete disablePortal id={getCatData?._id} options={getCatData} getOptionLabel={getCatData => getCatData?.cat_name}
                  onChange={(event, value) => {
                    cat_id = value
                  }}
                  onSelect={() => {
                    dispatch(getSubCategoryAction({ is_deleted: false, cat_id: cat_id?._id }))
                  }}
                  renderInput={(params) => <TextField {...register('cat_name')} {...params} label="Category Name" error={errors?.cat_name?.message} helperText={errors?.cat_name?.message} />} />

                <Autocomplete disablePortal id={getSubCatData?._id} options={getSubCatData} getOptionLabel={getSubCatData => getSubCatData?.sub_cat_name}
                  onChange={(event, value) => {
                    sub_cat_id = value
                  }}
                  renderInput={(params) => <TextField {...register('sub_cat_name')} {...params} label="Sub Category Name" error={errors?.sub_cat_name?.message} helperText={errors?.sub_cat_name?.message} />} />


                <TextField label="Question" variant="outlined" placeholder='Question' {...register('question')} error={errors?.question?.message} helperText={errors?.question?.message} />

                <FormControl>
                  <FormLabel>Answer Type</FormLabel>
                  <RadioGroup onChange={handleAnswerType}>
                    <Stack direction='row'>
                      <FormControlLabel value="conditional" {...register('answer_type')} control={<Radio />} label="Condtional" />
                      <FormControlLabel value="optional" {...register('answer_type')} control={<Radio />} label="Optional" />
                      <FormControlLabel value="MCQ"  {...register('answer_type')} control={<Radio />} label="Multiple Choise" />
                    </Stack>
                  </RadioGroup>
                  <FormHelperText error={errors?.answer_type?.message}>{errors?.answer_type?.message}</FormHelperText>
                </FormControl>

                {
                  (answer_type == 'optional' || answer_type == 'MCQ') &&
                  <>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={10}>
                        <TextField value={answerOption} onChange={(event) => setAnswerOption(event.target.value)} fullWidth label="Answer Options" variant="outlined" placeholder='Answer Options' />
                      </Grid>

                      <Grid item xs={1}>
                        <IconButton disabled={!answerOption} color='primary' onClick={handleAddAnsOpt} ><AddCircleIcon fontSize='large' /></IconButton>
                      </Grid>
                    </Grid>
                    <FormHelperText error={errors?.ansOps?.message}>{errors?.ansOps?.message}</FormHelperText>

                    <Stack spacing={1}>
                      {ansArr.map((items, index) => {
                        return (
                          <Stack direction='row' spacing={2} key={index}>
                            <Chip variant="outlined" key={index} label={items} onDelete={() => handleDelAnsOpt(index)} />
                          </Stack>
                        )
                      })}
                    </Stack>
                  </>
                }

                {
                  answer_type == 'conditional' &&
                  <FormControl>
                    <FormLabel>Answer Options</FormLabel>
                    <RadioGroup onChange={handleAnsChange}>
                      <Stack direction='row'>
                        <FormControlLabel value='AgreeDisagree' control={<Radio />} label="Agree Disagree" />
                        <FormControlLabel value='TrueFalse' control={<Radio />} label="True False" />
                        <FormControlLabel value='YesNo' control={<Radio />} label="Yes No" />
                      </Stack>
                      <Typography variant='subtitle2' color='error'>{errors?.answer_option?.message}</Typography>
                    </RadioGroup>
                    <FormHelperText error={errors?.ansOps?.message}>{errors?.ansOps?.message}</FormHelperText>
                  </FormControl>
                }

                {errors?.duplicateAnsOpt?.message && <Typography variant='subtitle2' color='error'>{errors?.duplicateAnsOpt?.message}</Typography>}
                {errors?.singleOptionType?.message && <Typography variant='subtitle2' color='error'>{errors?.singleOptionType?.message}</Typography>}


                <Stack direction='row' spacing={2}>
                  <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Create Q & A'}</Button>
                  <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                </Stack>
              </Stack>
            </form>
          </Card>
        </Box>
      </Modal>

      <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
    </>
  )
}



CreateQuestionAnswer.propTypes = {
  state: PropTypes.any,
  setState: PropTypes.any
};