// import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import Participents from '../../assets/icon/participants.png'
import { Box, Card, CardActionArea, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import LoadingUI from '../../comman/LoadingUI'
import PropTypes from 'prop-types';

export default function NoOfParticipent({ data, loading }) {

    return (
        <Grid item xs={4}>
            <Card sx={{ bgcolor:"#071D45", p:2}}>
                <Link style={{ textDecoration: 'none' }} to='/analytics/user-details'>
                    <CardActionArea>
                        <Stack spacing={2} alignItems={'center'}>
                            <Box sx={{ width: 70, height: 70, borderRadius: 1, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {/* <PeopleAltIcon sx={{ fontSize: 50 }} /> */}
                                <img src={Participents} style={{minWidth:"10px"}}/>

                            </Box>
                            <Typography variant='subtitle2' color='#fff'>Number of Participant</Typography>
                            {loading ? <LoadingUI /> : null}
                            <Typography variant='h3' color='#fff'>{data || "--"}</Typography>
                        </Stack>
                    </CardActionArea>
                </Link >
            </Card>
        </Grid >
    )
}



NoOfParticipent.propTypes = {
    data: PropTypes.any,
    loading: PropTypes.any
};