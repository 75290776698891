import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackBarUI from "../../comman/SnackBarUI";
import { deleteSurveyAction } from "../../redux/toolkit/survey/deleteSurvey";
import { getSurveyAction } from "../../redux/toolkit/survey/getSurvey";
import PropTypes from "prop-types";
import { getSingleSurveyAction } from "../../redux/toolkit/survey/getSignleSurvey";

export default function DeleteSurvey({ survey_id, state, setState }) {
  // state
  const dispatch = useDispatch();
  const delSurvey = useSelector((state) => state.deleteSurvey);
  const { status, message } = delSurvey;
  const { data } = useSelector((state) => state.getSingleSurvey);
  const [snack, setSnack] = useState(false);
  const [canNotDelete, setCanNotDelete] = useState(true);

  // fn
  const handleClose = () => {
    setState(false);
  };
  useEffect(() => {
    const getStatus = async () => {
      try {
        await dispatch(getSingleSurveyAction(survey_id));
      } catch (error) {
        console.log(error.message);
      }
    };
    getStatus();
  }, [state, dispatch]);

  useEffect(() => {
    if (data?.status === "live") {
      setCanNotDelete(false);
    }else{
      setCanNotDelete(true)
    }
    
  }, [data]);

  

  const delCatHandle = async () => {
    const deleteSurveyData = await dispatch(deleteSurveyAction(survey_id));
    if (deleteSurveyData?.payload?.status == 201) {
      await dispatch(getSurveyAction({ is_deleted: false }));
    }
    setSnack(true);
    setState(false);
  };

  return (
    <>
      <Dialog open={state} onClose={handleClose}>
        <DialogTitle>Deleting request !</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {canNotDelete
              ? "Are you sure you want to delete the selected item ?"
              : "You Can Not Delete Once Survey Is Live"}
          </DialogContentText>
        </DialogContent>
        {canNotDelete && (
          <DialogActions>
            <Button onClick={delCatHandle} autoFocus>
              {" "}
              Agree
            </Button>
            <Button onClick={handleClose}>Disagree</Button>
          </DialogActions>
        )}
      </Dialog>

      <SnackBarUI
        state={snack}
        setState={setSnack}
        status={status}
        message={message}
      />
    </>
  );
}

DeleteSurvey.propTypes = {
  survey_id: PropTypes.string,
  state: PropTypes.any,
  setState: PropTypes.any,
};
