import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, Card, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { createSubCategoryAction } from '../../redux/toolkit/sub-category/createSubCategory';
import { getSubCategoryAction } from '../../redux/toolkit/sub-category/getSubCategory';
import PropTypes from 'prop-types';

// varitable
let cat_id = ''

// schema
const catSchema = yup.object({
    cat_name: yup.string().required('Category name is required.'),
    sub_cat_name: yup.string().required('Category name is required.'),
    sequence_number: yup.number().positive().required('Category name is required.')
}).required();

export default function CreateSubCatagory({ state, setState }) {
    // state
    const { register, control, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(catSchema) });

    const dispatch = useDispatch()
    const createSubCat = useSelector(state => state.createSubCategory)
    const { status, message, loading } = createSubCat
    const getCategory = useSelector(state => state.getCategory)
    const { data: getCatData } = getCategory

    const [snack, setSnack] = useState(false)
    // fn
    const handleCategory = async (data) => {
        await dispatch(createSubCategoryAction(
            {
                cat_id: cat_id?._id,
                sub_cat_name: data?.sub_cat_name,
                sequence_number: data?.sequence_number
            }
        ))
        await dispatch(getSubCategoryAction({ cat_id: cat_id?._id, is_deleted: false }))
        setSnack(true)
        setState(false)
        reset()
    }

    const handleClose = function () {
        setState(false)
    }


    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 400, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Create Sub Category</Typography>
                        <form onSubmit={handleSubmit(handleCategory)}>
                            <Stack spacing={3}>
                                <Autocomplete disablePortal id={getCatData?._id} options={getCatData} getOptionLabel={getCatData => getCatData?.cat_name}
                                    onChange={(event, value) => {
                                        cat_id = value
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...register('cat_name')} {...params} label="Category Name" error={errors?.cat_name?.message} helperText={errors?.cat_name?.message} />}
                                />
                                <TextField type='text' label="Sub Category Name" variant="outlined" placeholder='Sub Category Name' {...register('sub_cat_name')} error={errors?.sub_cat_name?.message} helperText={errors?.sub_cat_name?.message} />
                                <TextField type='number' InputProps={{ inputProps: { min: 1 } }} label="Sequence" variant="outlined" placeholder='Sub Category Sequence' {...register('sequence_number')} error={errors?.sequence_number?.message} helperText={errors?.sequence_number?.message} />
                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Create Sub Category'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>
            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
            <DevTool control={control} />
        </>
    )
}


CreateSubCatagory.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any,
};