import CachedIcon from '@mui/icons-material/Cached'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, Button, Card, IconButton, Link, Paper, Stack, TableContainer, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import Appbar from '../../components/Appbar'
import DrawerUI from '../../components/Drawer'
import Body from '../../layout/Body'
import { getSurveyLinkedQnsAction } from '../../redux/toolkit/survey/getSurveyLinkedQns'
import { getUserSurveyDetailsAction } from '../../redux/toolkit/survey/getUserSurveyDetails'
import { useStyles } from '../../theme/theme'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import UploadSurveyPic from './UploadSurveyPic'
const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/survey'>Survey</Link>
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/survey-detail'>Survey details</Link>
        </Breadcrumbs >
        <Typography variant='h5'>Survey Details</Typography>
    </Stack >
)


export default function SurveyDetails() {
    // state
    const dispatch = useDispatch()
    const survey_id = useLocation()?.search?.split("?")[1]
    const[openUploadPic,setOpenUploadPic] = useState(false)
    const getSurveyLinkedQns = useSelector(state => state.getSurveyLinkedQns)
    const { data: getSurveyLinkedQnsData, loading: getSurveyLinkedQnsLoading } = getSurveyLinkedQns
    const getUserSurveyDetails = useSelector(state => state.getUserSurveyDetails)
    const { data: getUserSurveyDetailsData, loading: getUserSurveyDetailsLoading } = getUserSurveyDetails



    // console.log("getSurveyLinkedQnsData", getSurveyLinkedQnsData)

    const [pageSize, setPageSize] = useState(25);
    const classes = useStyles();
    const navigate = useNavigate()

    const surveyDetailsColumn = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
           
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
        
            minWidth: 300,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    params?.row?.user_id?._id
                )
            },
        },
        
        {
            field: 'email',
            headerName: 'Email',
        
            minWidth: 300,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    params?.row?.user_id?.email
                )
            },
        },
        {
            field: 'attempts',
            headerName: 'Total Attempts',
          
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    params?.row?.user_id?.survey_complete
                )
            },
        },
        {
            field: 'rating',
            headerName: 'Rating',
            flex: 1,
            minWidth: 170,
            headerClassName: 'super-app-theme--header',
            
        },
        {
            field: 'publisher_id.publisher_name',
            headerName: 'Publisher Name',
         
            minWidth: 250,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    params?.row?.publisher_id?.publisher_name
                )
            },
        },
    ]


    // fn
    const handleRefresh = () => {
        dispatch(getUserSurveyDetailsAction({ survey_id: survey_id }))
    }

    // useEffect
    useEffect(() => {
        dispatch(getSurveyLinkedQnsAction(survey_id))
        dispatch(getUserSurveyDetailsAction(survey_id))
    }, [])
console.log(getUserSurveyDetailsData)
    return (
        <>
            <Helmet><title>Quick survey | Survey-detail</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            <DrawerUI />
            <Body>

                <Card sx={{ p: 3, bgcolor: 'primary.main', color: '#fff' }}>
                    <Stack spacing={2}>
                        <Box>
                            <Stack direction="row" sx={{
                                width:"100%",
                                justifyContent:"space-between"
                                
                            }}>
                            <Typography variant='h6'>Linked Questions</Typography>
                            <Stack direction={"row"} gap={3}>
                            <Button variant='contained' color='success' onClick={()=>setOpenUploadPic(true)} startIcon ={<CloudUploadOutlinedIcon/>}>Upload Pic</Button>
                                <Button variant='contained' color='success' onClick={()=>navigate(`/survey/link-questions/${survey_id}`)} >Add Questions</Button>
                                <Button variant='contained' color='error' onClick={()=>navigate(`/survey/unlink-questions/${survey_id}`)}>Remove Questions</Button>
                            </Stack>
                            </Stack>
                            <Box sx={{ my: 1, ml: 1 }}>
                                {getSurveyLinkedQnsLoading ? <LoadingUI /> :
                                    getSurveyLinkedQnsData?.map((item, index) => {
                                        return (
                                            <Stack key={index} sx={{ my: 2 }}>
                                                <Stack direction={'row'} alignItems='center'>
                                                    <Typography sx={{ color: 'yellow' }}>Question : </Typography>
                                                    <Typography sx={{ ml: 1 }} variant='subtitle2'>{item?.question}</Typography>
                                                </Stack>
                                                <Stack direction={'row'} alignItems='center'>
                                                    <Typography sx={{ color: 'yellow' }}> Answer Options :</Typography>
                                                    {item?.ans_options?.map((item, i) => {
                                                        return <Typography sx={{ ml: 1 }} key={i}>{item}</Typography>
                                                    })}
                                                </Stack>
                                            </Stack>
                                        )
                                    })}
                            </Box>
                        </Box>
                    </Stack>
                </Card>


                {/* <Card sx={{ mt: 2, p: 2 }}>
                    <Typography variant='h6'>Get Complete Survey By Users</Typography>
                    <Stack direction={'row'} alignItems='center' spacing={2}>
                        {noOfQualifiedSurveyLoading ? <LoadingUI /> : <Typography variant='h4' color='primary'>{noOfQualifiedSurveyData?.complete_survey_users || "--"}</Typography>}
                    </Stack>

                    <Button sx={{ mt: 1 }} onClick={handlenoOfQualifiedSurvey} variant='contained'>Get Users Data</Button>
                </Card> */}



                <Card sx={{ mt: 2, }}>
                    <TableContainer sx={{ width: '100%' }}>
                        <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}>
                            <Typography sx={{ ml: 3, my: 2 }} variant='h6'>User List</Typography>
                            <IconButton sx={{ mr: 3, my: 2 }} onClick={handleRefresh}><CachedIcon /></IconButton>
                        </Stack>

                        <Paper sx={{
                            bgcolor: '#fff',
                            '& .super-app-theme--header': {
                                bgcolor: '#071D45',
                                color: '#fff',
                            },
                        }} elevation={0}>
                            <DataGrid
                                className={classes.root}
                                getRowHeight={() => 'auto'}
                                sx={{ border: 0 }}
                                autoHeight
                                rows={getUserSurveyDetailsData}
                                columns={surveyDetailsColumn}
                                getRowId={(row) => row._id}
                                disableSelectionOnClick
                                experimentalFeatures={{ newEditingApi: true }}
                                pagination
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowsPerPageOptions={[25, 50, 100]}
                                disableColumnSelector
                                loading={getUserSurveyDetailsLoading ? <LoadingUI /> : false}
                                components={{
                                    NoRowsOverlay: () => <NoDataFound />,
                                    Toolbar: GridToolbar,
                                }}

                            />
                        </Paper>
                    </TableContainer>
                </Card>

            </Body>
            <UploadSurveyPic state={openUploadPic} setState={setOpenUploadPic} survey_id={survey_id}/>
        </>
    )
}
