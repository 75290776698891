import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Button, Link, Stack, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import Appbar from "../../../components/Appbar";
import DrawerUI from "../../../components/Drawer";

import Body from "../../../layout/Body";

import { useDispatch, useSelector } from "react-redux";

import { getSingleBankDetailsAction } from "../../../redux/toolkit/publisher/bank-details/getSingleBank";
import { useNavigate, useParams } from "react-router-dom";
import { getVerifyBank } from "../../../redux/toolkit/publisher/bank-details/verifyBank";
import LoadingUI from "../../../comman/LoadingUI";
import SnackBarUI from "../../../comman/SnackBarUI";

const breadcrumb = (
  <Stack>
    <Breadcrumbs
      separator={
        <NavigateNextIcon sx={{ color: "#5E6C8A" }} fontSize="small" />
      }
      aria-label="breadcrumb"
    >
      <Link
        underline="hover"
        sx={{ color: "#5E6C8A" }}
        color="inherit"
        href="/"
      >
        Home
      </Link>
      <Link
        underline="hover"
        sx={{ color: "#5E6C8A" }}
        color="inherit"
        href="/analytics"
      >
        Bank Details
      </Link>
    </Breadcrumbs>
    <Typography sx={{ color: "#5E6C8A" }} variant="h5">
      Details{" "}
    </Typography>
  </Stack>
);

export default function IndividualBankDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [snake, setSnake] = useState(false);
  const { data } = useSelector((state) => state.getSingleBank);
  const { loading, message, status } = useSelector(
    (state) => state.getVerifyBank
  );

  const { id } = useParams();

  useEffect(() => {
    dispatch(getSingleBankDetailsAction({ id: id }));
  }, []);

  const handleVerifyBank = async () => {
    try {
      await dispatch(getVerifyBank({ id: id }));
      setSnake(true)
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      <Helmet>
        <title>Bank Details</title>
      </Helmet>

      <Appbar breadcrumb={breadcrumb} />
      <DrawerUI />
      <Body>
        <Stack
          padding={3}
          direction={"row"}
          gap={10}
          justifyContent={"center"}
          bgcolor={"white"}
          borderRadius={2}
        >
          <Stack direction={"column"} alignItems={"flex-start"} gap={2}>
            <Typography>Account Holder Name</Typography>
            <Typography>Account Type</Typography>
            <Typography>Buisness Name</Typography>
            <Typography>Account Number</Typography>
            <Typography>IFSC Code</Typography>
            <Typography>Branch</Typography>
            <Typography>Address</Typography>
            <Typography>Postal Code</Typography>
            <Typography>City</Typography>
            <Typography>Region</Typography>
          </Stack>
          <Stack color={"darkmagenta"} gap={2}>
            <Typography>{data?.holder_name}</Typography>
            <Typography>{data?.account_type}</Typography>
            <Typography>{data?.bussiness_name}</Typography>
            <Typography>{data?.account_no}</Typography>
            <Typography>{data?.ifcs_code}</Typography>
            <Typography>{data?.branch}</Typography>
            <Typography>{data?.address} </Typography>
            <Typography>{data?.postal_code}</Typography>
            <Typography>{data?.city}</Typography>
            <Typography>{data?.region}</Typography>
          </Stack>
        </Stack>
        <Stack direction={"row"} justifyContent={"center"} gap={4} m={5}>
         {!data?.is_varified &&  <Button
            variant={"contained"}
            onClick={handleVerifyBank}
            disabled={loading}
          >
            {loading ? <LoadingUI /> : "Approve"}
          </Button>  }
          <Button
            variant={"contained"}
            style={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/bank-details")}
          >
            Back
          </Button>
        </Stack>
        <SnackBarUI
          state={snake}
          setState={setSnake}
          message={message}
          status={status}
        />
      </Body>
    </>
  );
}
