import BlockIcon from '@mui/icons-material/Block'
import DoneIcon from '@mui/icons-material/Done'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, Button, Card, Chip, Grid, IconButton, Link, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import SnackBarUI from '../../comman/SnackBarUI'
import Appbar from '../../components/Appbar'
import DrawerUI from '../../components/Drawer'
import Body from '../../layout/Body'
import { getAdminSupportAction } from '../../redux/toolkit/admin/getAdminSupports'
import { getSingleAdminSupportAction } from '../../redux/toolkit/admin/getSingleAdminSupports'
import { useStyles } from '../../theme/theme'
import AddPermission from './AddPermission'
import BlockUnBlockAdmin from './BlockUnBlockAdmin'
import CreateAdmin from './CreateAdmin'

let user_id = null

const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/admin'>Admin</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Admin</Typography>
    </Stack>
)



export default function Admin() {
    // state
    const dispatch = useDispatch()
    const getAdminSupportSelector = useSelector(state => state.getAdminSupport)
    const { data, loading } = getAdminSupportSelector
    const blockAndUnBlockAdminSelector = useSelector(state => state.blockAndUnBlockAdmin)
    const { status, message } = blockAndUnBlockAdminSelector

    const [editPremission, setEditPremission] = useState(false)
    const [createAdmin, setCreateAdmin] = useState(false)
    const [admin_id, setadmin_id] = useState('')
    const [pageSize, setPageSize] = useState(25);
    const [blockAdmin, setBlockAdmin] = useState(false);
    const [is_block, setIs_block] = useState(false);
    const [snack, setSnack] = useState(false)
    const classes = useStyles();



    const adminColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'permission',
            headerName: 'Permissions',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Grid container spacing={1}>
                        {params?.row?.permission?.map((items, index) => {
                            return (
                                <Grid item key={index}>
                                    <Chip color='warning' label={items} />
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            },

        },
        {
            field: 'settings',
            headerName: 'Delete Authority',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.settings?.can_delete ? 'Yes' : 'No'}</Typography>
                )
            },

        },
        {
            field: 'edit', headerName: 'Edit Category',
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => {
                        setEditPremission(true)
                        dispatch(getSingleAdminSupportAction(params?.row?._id))
                    }}><EditIcon color='#071D45' />
                    </IconButton>
                )
            },
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'is_block',
            headerName: 'Block',
            renderCell: (params) => {
                return (
                    <>
                        {
                            !params?.row?.is_block
                                ?
                                <IconButton onClick={() => {
                                    setBlockAdmin(true)
                                    setIs_block(true)
                                    user_id = params?.row?._id
                                }} >
                                    <DoneIcon color='primary' />
                                </IconButton>
                                :
                                <IconButton
                                    onClick={() => {
                                        setBlockAdmin(true)
                                        setIs_block(false)
                                        user_id = params?.row?._id
                                    }}>
                                    <BlockIcon color='error' />
                                </IconButton>
                        }

                    </>

                )
            },
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
    ]


    // useEffec
    useEffect(() => {
        dispatch(getAdminSupportAction())
    }, [dispatch])


    return (
        <>
            <Helmet>
                <title>Quick survey | Admin</title>
            </Helmet>


            <Appbar breadcrumb={breadcrumb} />
            <DrawerUI />
            <Body>
                <Card sx={{ p: 3, bgcolor: '#071D45', color: '#fff' }}>
                    <Stack spacing={2}>
                        <Typography variant='h6'>Category List</Typography>
                        <Box><Button sx={{ mt: 2, bgcolor: '#fff', color: '#000' }} variant='contained' onClick={() => setCreateAdmin(true)}>Create Support / Admin</Button></Box>
                    </Stack>
                </Card>

                < Card sx={{ my: 2 }}>
                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: '#071D45',
                            color: '#fff',
                        },
                    }} elevation={0}>
                        <DataGrid
                            className={classes.root}
                            getRowHeight={() => 'auto'}
                            sx={{ border: 0 }}
                            rows={data}
                            autoHeight
                            columns={adminColumns}
                            getRowId={(row) => row._id}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            pagination
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[25, 50, 100]}
                            disableColumnSelector
                            loading={loading ? <LoadingUI /> : false}
                            onCellClick={(params) => {
                                setadmin_id(params?.row?._id)
                            }}
                            components={{
                                NoRowsOverlay: () => <NoDataFound />,
                                Toolbar: GridToolbar,
                            }}

                        />
                    </Paper>
                </Card >



                {/* modals */}
                < AddPermission admin_id={admin_id} state={editPremission} setState={setEditPremission} />
                <CreateAdmin state={createAdmin} setState={setCreateAdmin} />
                <BlockUnBlockAdmin is_block={is_block} user_id={user_id} state={blockAdmin} setState={setBlockAdmin} />


            </Body >

            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}
