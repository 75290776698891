import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Button, Card, IconButton, Link, Paper, Stack, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../../comman/LoadingUI'
import NoDataFound from '../../../comman/NoDataFound'
import Appbar from '../../../components/Appbar'
import DrawerUI from '../../../components/Drawer'
import Body from '../../../layout/Body'
import { getFAQAction } from '../../../redux/toolkit/publisher/support/getFAQs'
import { getSingleFAQAction } from '../../../redux/toolkit/publisher/support/getSingleFAQ'
import { useStyles } from '../../../theme/theme'
import CreateFAQ from './CreateFAQ'
import DeleteFAQ from './DeleteFAQ'
import UpdateFAQ from './UpdateFAQ'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'


const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/support-knowledge'>Support & Knowledge</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Publisher</Typography>
    </Stack>
)

export default function SupportKnowledge() {

    const FAQ = useSelector(state => state.getFAQ)
    const { data, loading } = FAQ
    const permission = useSelector(state => state.getPermissionAndRole)
    const { data: permissionData } = permission

    const dispatch = useDispatch()

    const [createFAQ, setCreateFAQ] = useState(false)
    const [updateFAQ, setUpdateFAQ] = useState(false)
    const [delFAQ, setDelFAQ] = useState(false)
    const [id, setId] = useState('')
    const classes = useStyles();
    const [pageSize, setPageSize] = useState(25);

    let can_delete = JSON.parse(localStorage.getItem("ss_active_usr"))?.settings?.can_delete
    let super_admin = permissionData?.role


    const faqDataGridColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'title',
            headerName: 'Title',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'answers',
            headerName: 'Answers',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'edit', headerName: 'Edit Category',
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                            setUpdateFAQ(true);
                            dispatch(getSingleFAQAction(params?.row?._id))
                        }}><EditIcon color='primary' />
                    </IconButton>
                )
            },
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'delete', headerName: 'Delete Category', renderCell: () => {
                return (
                    <IconButton
                        onClick={() => {
                            setDelFAQ(true);
                        }}><DeleteIcon color='error' />
                    </IconButton>
                )
            },
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 150,
            hide: (!can_delete && super_admin != 'super_admin')

        },
    ]

    useEffect(() => {
        dispatch(getFAQAction({ is_deleted: false }))
    }, [dispatch])



    return (
        <>
            <Helmet><title> Quick survey | Publisher </title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            <DrawerUI />
            <Body>
                <Card sx={{ p: 3, bgcolor: '#071D45', color: '#fff' }}>
                    <Typography variant='h6'>FAQ section</Typography>
                    <Typography variant='subtitle1'>You can create FAQ question & answers from here.</Typography>
                    <Button sx={{ mt: 2, bgcolor: '#fff', color: '#000' }} variant='contained' onClick={() => setCreateFAQ(true)}>Create FAQ Question</Button>
                </Card>

                <Card sx={{ my: 2 }}>
                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: '#071D45',
                            color: '#fff',
                        },
                    }} elevation={0}>


                        <DataGrid
                            className={classes.root}
                            sx={{
                                border: 0,
                            }}
                            getRowHeight={() => 'auto'}
                            autoHeight
                            rows={data}
                            columns={faqDataGridColumns}
                            getRowId={(row) => row._id}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            pagination
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[25, 50, 100]}
                            disableColumnSelector
                            loading={loading ? <LoadingUI /> : false}
                            onCellClick={(params) => {
                                setId(params?.row?._id)
                            }}
                            components={{
                                NoRowsOverlay: () => <NoDataFound />,
                                Toolbar: GridToolbar,

                            }}

                        />
                    </Paper>
                </Card>

            </Body>
            <CreateFAQ state={createFAQ} setState={setCreateFAQ} />
            <UpdateFAQ id={id} state={updateFAQ} setState={setUpdateFAQ} />
            <DeleteFAQ id={id} state={delFAQ} setState={setDelFAQ} />

        </>
    )
}
