// import LiveTvIcon from '@mui/icons-material/LiveTv';
import live  from '../../assets/icon/live.png'
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingUI from '../../comman/LoadingUI';
import { noOfLiveSurveyAction } from '../../redux/toolkit/menu/noOfLiveSurvey';

export default function LiveSurvey() {
    // state
    const dispatch = useDispatch()


    const liveSurveySelector = useSelector(state => state.noOfLiveSurvey)
    const { data, loading } = liveSurveySelector

    // useeffect
    useEffect(() => {
        dispatch(noOfLiveSurveyAction())
    }, [dispatch])


    return (
        <Grid item xs={4}>
            <Card sx={{ p: 2, bgcolor:"#071D45"}}>
                <Stack spacing={2} alignItems={'center'}>
                    <Box sx={{ width: 70, height: 70, borderRadius: 1, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <LiveTvIcon sx={{ fontSize: 50 }} /> */}
                        <img src={live} style={{minWidth:"10px"}}/>

                    </Box>
                    <Typography variant='subtitle2' color="#fff">Live survey</Typography>
                    {loading ? <LoadingUI /> : null}
                    <Typography variant='h3' color='#fff'>{data || "--"}</Typography>
                </Stack>
            </Card>
        </Grid >
    )
}
