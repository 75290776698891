import {
  Breadcrumbs,
  Button,
  Card,

  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import DrawerUI from "../../components/Drawer";
import Body from "../../layout/Body";
import Appbar from "../../components/Appbar";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { useDispatch, useSelector } from "react-redux";
import { getSurveyLinkedQnsAction } from "../../redux/toolkit/survey/getSurveyLinkedQns";
import { DataGrid } from "@mui/x-data-grid";

import { unLinkQNsToSurveyAction } from "../../redux/toolkit/survey/unlinkQnsToSurvey";
import SnackBarUI from "../../comman/SnackBarUI";

const breadcrumb = (
  <Stack>
    <Breadcrumbs
      separator={
        <NavigateNextIcon sx={{ color: "inherit" }} fontSize="small" />
      }
      aria-label="breadcrumb"
    >
      <Link underline="hover" color="inherit" href="/">
        Home
      </Link>
      <Link underline="hover" color="inherit" href="/survey">
        Survey
      </Link>
      <Link underline="hover" color="inherit" href="/survey-detail">
        Survey details
      </Link>
    </Breadcrumbs>
    <Typography variant="h5">Unlink Questions</Typography>
  </Stack>
);
const UnlinkQuestions = () => {
  const { surveyId } = useParams();
  const { message,status, loading } = useSelector((state) => state.unLinkQNsToSurvey);
  const { data } = useSelector((state) => state.getSurveyLinkedQns);
  const [questionsId, setQuestionsId] = useState({});
  const[snake,setSnake] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSurveyLinkedQnsAction(surveyId));
  }, []);
  const getSelectedQuestionsId = (ids) => {
    setQuestionsId({questionsId:ids,survey_id:surveyId});
  };
  console.log(data, loading);
  console.log(questionsId, "qns");
  const unlinkSurveyQuestions = async () => {
    await dispatch(unLinkQNsToSurveyAction(questionsId));
    await dispatch(getSurveyLinkedQnsAction(surveyId));
    setSnake(true)

  };
  console.log(questionsId)
  const columns = [
    {
      field: "_id",
      headerName: "ID",

      minWidth: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "question",
      headerName: "Questions",

      minWidth: 650,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "Action",

      minWidth: 100,
      headerClassName: "super-app-theme--header",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Quick survey | Unlink Questions</title>
      </Helmet>
      <Appbar breadcrumb={breadcrumb} />
      <DrawerUI />
     
        <Body>
          <Card sx={{ p: 3, bgcolor: "#fff" }}>
            <Stack
              sx={{
                width: "100%",
                justifyContent: "flex-end",
              }}
              direction={"row"}
            >
              <Button
                color="error"
                sx={{
                  width: "150px",
                }}
                
                disabled = {loading}
                variant="contained"
                onClick={unlinkSurveyQuestions}
              >
                {"Unlink"}
              </Button>
            </Stack>
            <br />
            <Paper
              sx={{
                bgcolor: "#fff",
                "& .super-app-theme--header": {
                  bgcolor: "#071D45",
                  color: "#fff",
                },
              }}
              elevation={0}
            >
              <DataGrid
                rows={data}
                columns={columns}
                autoHeight
                disableRowSelectionOnClick
                checkboxSelection
                getRowId={(row) => row._id}
                onSelectionModelChange={(ids) => getSelectedQuestionsId(ids)}
              />
            </Paper>
          </Card>
        </Body>
    
      <SnackBarUI message={message} state={snake} setState={setSnake} status={status}/>
    </>
  );
};

export default UnlinkQuestions;
