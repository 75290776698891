import { Card, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import CachedIcon from '@mui/icons-material/Cached';
import { Graph_SurveyStatsGraph } from './Graph_SurveyStatsGraph';
import { useDispatch } from 'react-redux';
import { surveyStatsAction } from '../../redux/toolkit/dashbaord/surveyStats';

export default function SurveyStats() {
    // state
    const dispatch = useDispatch()


    // fn
    const handleRefresh = () => {
        dispatch(surveyStatsAction())
    }

    // useEffect
    useEffect(() => {
        dispatch(surveyStatsAction())
    }, [dispatch])


    return (
        <Card sx={{ p: 2,color: '#fff' }}>
            <Stack direction={'row'} justifyContent='space-between'>
                <Typography variant='h6' color='#5E6C8A' sx={{fontFamily: 'Montserrat,sans-serif'}}>Survey Stats</Typography>
                <IconButton onClick={handleRefresh} sx={{ mb: 1, color: '#fff' }}><CachedIcon /></IconButton>
            </Stack>
            <Graph_SurveyStatsGraph />
        </Card>
    )
}
