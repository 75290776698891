import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAxios from "../../../config/authAxios";

const initialState = {
  status: null,
  loading: false,
  data: [],
  error: "",
  message: "",
};

export const noOfUpcomingSurveyActions = createAsyncThunk(
    "upcoming-survey",
   
    async ( data , { rejectWithValue }) => {
      console.log("working");
      try {
        const response = await authAxios.get(
          `/support-server/api/upcoming-survey?s_date=${data?.s_date}&e_date=${data?.e_date}`
        );
        return response?.data;
      } catch (error) {
        return rejectWithValue(error?.response?.data);
        
      }
    }
  );
  

const noOfUpcomingSurveySlice = createSlice({
  name: "upcoming-survey",
  initialState,
  reducers: {},
  extraReducers: 
  (builder)=>{
    builder.addCase(noOfUpcomingSurveyActions.pending, (state) => {
        state.loading = true
    })
    builder.addCase(noOfUpcomingSurveyActions.fulfilled, (state, action) => {
        state.loading = false
        state.data = action?.payload?.data
        state.status = action?.payload?.status
        state.message = action?.payload?.message
    })
    builder.addCase(noOfUpcomingSurveyActions.rejected, (state, action) => {
        state.loading = false
        state.error = action?.payload?.error
        state.status = action?.payload?.status
        state.message = action?.payload?.message
        
    })
  }
    
});

export const noOfUpcomingSurveyReducer = noOfUpcomingSurveySlice.reducer;
