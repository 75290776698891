// import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { FaUsersRectangle } from 'react-icons/fa6'
import { Box, Card, CardActionArea, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import LoadingUI from '../../comman/LoadingUI'
import PropTypes from 'prop-types';

export default function UserCount({ data, loading }) {

    return (
        <Grid item xs={4}>
            <Card sx={{ bgcolor: "#071D45", p: 2 }}>
                <Link style={{ textDecoration: 'none' }} to='/analytics/user-details'>
                    <CardActionArea>
                        <Stack spacing={2} alignItems={'center'}>
                            <Box sx={{ width: 70, height: 70, borderRadius: 1, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {/* <PeopleAltIcon sx={{ fontSize: 50 }} /> */}
                                <FaUsersRectangle style={{
                                    fontSize: "4rem"
                                }} />

                            </Box>
                            <Typography variant='subtitle2' color='#fff'>Total Users</Typography>
                            {loading ? <LoadingUI /> : null}
                            <Typography variant='h3' color='#fff'>{data || "--"}</Typography>
                        </Stack>
                    </CardActionArea>
                </Link >
            </Card>
        </Grid >

    )
}



UserCount.propTypes = {
    data: PropTypes.any,
    loading: PropTypes.any
};