import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import authAxios from "../../../config/authAxios"





const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const getPostbackLogsAction = createAsyncThunk(
    'get-postback-logs',
    async (data, { rejectWithValue }) => {
        try {
            const res = await authAxios.get(`/support-server/api/get-postback-logs`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const PostbackLogsSlice = createSlice({
    name: 'get-postback',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(getPostbackLogsAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(getPostbackLogsAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(getPostbackLogsAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const PostbackLogsReducer = PostbackLogsSlice.reducer