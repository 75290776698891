import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import PropTypes from "prop-types";
import { blockUserAction } from "../../redux/toolkit/users/blockUser";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsAction } from "../../redux/toolkit/users/userDetails";
import SnackBarUI from "../../comman/SnackBarUI";

const BlockUser = ({ open, close, userId, isBlocked }) => {
  const [snack, setSnack] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    close(false);
  };
  const { status,message } = useSelector((state) => state.blockUser);
  const handleBlockUser = async () => {
    await dispatch(blockUserAction({ id: userId }));
    dispatch(getUserDetailsAction());
    close(false);
    setSnack(true)
  };
  console.log(status);
  return (
    <>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Block User</DialogTitle>
      <DialogContent>
        {isBlocked ? (
          <DialogContentText>Do You Want To Unblock User?</DialogContentText>
        ) : (
          <DialogContentText>Do You Want To Block User?</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {isBlocked ? (
          <Button onClick={handleBlockUser}>Unblock</Button>
        ) : (
          <Button onClick={handleBlockUser}>Block</Button>
        )}
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
    <SnackBarUI
    status={status}
    state={snack}
    setState={setSnack}
    message={message}
    
    />
    </>
  );
};
BlockUser.propTypes = {
  open: PropTypes.any,
  close: PropTypes.func,
  userId: PropTypes.any,
  isBlocked:PropTypes.any
};
export default BlockUser;
