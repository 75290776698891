import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Chip, CircularProgress, FormHelperText, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../../comman/SnackBarUI';
import { updateFAQAction } from '../../../redux/toolkit/publisher/support/updateFAQ';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getFAQAction } from '../../../redux/toolkit/publisher/support/getFAQs';

// schema
const qns_ans_schema = yup.object({
    title: yup.string().required('Title is required.'),
}).required();


export default function UpdateFAQ({ id, state, setState }) {
    // state
    const dispatch = useDispatch()
    const updateQns = useSelector(state => state.updateFAQ)
    const { status, message, loading } = updateQns
    const getFAQ = useSelector(state => state.getSingleFAQ)
    const { data: { title, answers } } = getFAQ


    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(qns_ans_schema),
        defaultValues: {
            title: title
        }
    });

    const [ansArr, setAnsArr] = useState([])
    const [snack, setSnack] = useState(false)
    const [answerOption, setAnswerOption] = useState('')
console.log("message",message)
    // fn
    const handleDelAnsOpt = (i) => {
        setAnsArr((current) => current.filter((chips, index) => { return index !== i; }))
        setAnswerOption('')
    }

    const handleAddAnsOpt = () => {
        const foundDuplicate = ansArr.find(element => element == answerOption);
        if (foundDuplicate !== undefined) return
        setAnsArr(prev => [...prev, answerOption])
        setAnswerOption('')
    }


    const handleQns = async (data) => {
        let updateFAQ = await dispatch(updateFAQAction({ id: id, ...data, answers: ansArr }))
        if (updateFAQ?.payload?.status == 200) {
            dispatch(getFAQAction({ is_deleted: '' }))
        }
        setState(false)
        setSnack(true)
    }

    const handleClose = function () {
        setState(false)
    }


    useEffect(() => {
        let defaultValues = {};
        defaultValues.title = title;
        setAnsArr(answers)
        reset({ ...defaultValues });
    }, [title])



    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 500, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Update Question Answer</Typography>
                        <form onSubmit={handleSubmit(handleQns)}>
                            <Stack spacing={3}>
                                {title && <TextField defaultValue={title} type='text' label="Question" variant="outlined" placeholder='Question' {...register('title')} error={errors?.title?.message} helperText={errors?.title?.message} />}


                                <Grid container columnSpacing={1}>
                                    <Grid item xs={10}>
                                        <TextField value={answerOption} onChange={(event) => setAnswerOption(event.target.value)} fullWidth label="Answer Options" variant="outlined" placeholder='Answer Options' />
                                    </Grid>

                                    <Grid item xs={1}>
                                        <IconButton disabled={!answerOption} color='primary' onClick={handleAddAnsOpt} ><AddCircleIcon fontSize='large' /></IconButton>
                                    </Grid>
                                </Grid>
                                <FormHelperText error={errors?.ansOps?.message}>{errors?.ansOps?.message}</FormHelperText>

                                <Stack spacing={1}>
                                    {ansArr?.length && ansArr.map((items, index) => {
                                        return (
                                            <Stack direction='row' spacing={2} key={index}>
                                                <Chip variant="outlined" key={index} label={items} onDelete={() => handleDelAnsOpt(index)} />
                                            </Stack>
                                        )
                                    })}
                                </Stack>

                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Update Q & A'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>

            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
            <DevTool control={control} />
        </>
    )
}


UpdateFAQ.propTypes = {
    id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any
};
