import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CircularProgress,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import SnackBarUI from "../../../comman/SnackBarUI";
import { getSubCategoryAction } from "../../../redux/toolkit/sub-category/getSubCategory";
import { createSurveyAction } from "../../../redux/toolkit/survey/createSurvey";
import { getSurveyAction } from "../../../redux/toolkit/survey/getSurvey";
import PropTypes from "prop-types";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import LoadingUI from "../../../comman/LoadingUI";
import NoDataFound from "../../../comman/NoDataFound";
import Appbar from "../../../components/Appbar";
import DrawerUI from "../../../components/Drawer";
import Body from "../../../layout/Body";
import { getCategoryAction } from "../../../redux/toolkit/category/getCategory";
import { getSurveyQnsAction } from "../../../redux/toolkit/survey/getSurveyQns";
import { useStyles } from "../../../theme/theme";
import { uplodImageAction } from "../../../redux/toolkit/image/uplodImage";
let cat_id = "";
let sub_cat_id = "";
let imageUploadError;
const breadcrumb = (
  <Stack>
    <Breadcrumbs
      separator={<NavigateNextIcon sx={{ color: "#fff" }} fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link underline="hover" sx={{ color: "#fff" }} color="inherit" href="/">
        Home
      </Link>
      <Link
        underline="hover"
        sx={{ color: "#fff" }}
        color="inherit"
        href="/survey"
      >
        Survey
      </Link>
      <Link
        underline="hover"
        sx={{ color: "#fff" }}
        color="inherit"
        href="/survey/create-survey"
      >
        Create Survey
      </Link>
    </Breadcrumbs>
    <Typography sx={{ color: "#fff" }} variant="h5">
      Survey
    </Typography>
  </Stack>
);

// schema
const qns_ans_schema = yup
  .object({
    cat_name: yup.string().required("Category is required."),
    sub_cat_name: yup.string().required("Sub category is required."),
    survey: yup.number().positive().required("Survey is required."),

    reward_amt: yup.number().positive().required("reward amount is required."),

    secondary_reward: yup.string().required("reward amount is required."),

    survey_time: yup.string().required("survey time is required."),
  })
  .required();

export default function CreateSurvey() {
  // state
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(qns_ans_schema),
    defaultValues: {},
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createSurvey = useSelector((state) => state.createSurvey);
  const {
    status: createSurveyStatus,
    message: createSurveyMsg,
    loading: createSurveyLoading,
  } = createSurvey;
  const getCat = useSelector((state) => state.getCategory);
  const getQns = useSelector((state) => state.getSurveyQns);
  const { data: qnsData, loading: qnsLoading } = getQns;
  const { data: getCatData } = getCat;
  const getSubCat = useSelector((state) => state.getSubCategory);
  const { data: getSubCatData } = getSubCat;
  const [file, setFile] = useState("");
  const [snack, setSnack] = useState(false);
  const classes = useStyles();

  const [linkQnsList, setLinkQnsList] = useState([]);
  const [imageRequired, setImageRequired] = useState(false);
  const [cardBgColorVisible1, setCardBgColorVisible1] = useState(false);
  const [cardBgColorVisible2, setCardBgColorVisible2] = useState(false);
  const [cardBgColor, setCardBgColor] = useState({
    gradient1: "primary",
    gradient2: "primary",
  });
  const [pageSize, setPageSize] = useState(25);

  const linkedQnsColumn = [
    {
      field: "s_no",
      headerName: "S. No.",
      minWidth: 10,
      headerClassName: "super-app-theme--header",
      renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1,
    },
    {
      field: "question",
      headerName: "Questions",
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "question_type",
      headerName: "Question Type",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "question_reletive_cat",
      headerName: "Relitive Category",
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
    },
  ];

  // fn
  const handleQns = async (data) => {
    if (!file) {
      return setImageRequired(true);
    }
    if (cardBgColor?.gradient1 == "primary") {
      return setError("cardBgColor", {
        type: "custom",
        message: "Card colors are required.",
      });
    }
    if (!linkQnsList?.length) {
      return setError("linkQnsError", {
        type: "custom",
        message: "Atleast single question need to link to create new survey.",
      });
    }
    const formData = new FormData();
    formData.set("file", file);
    const imageUpload = await dispatch(uplodImageAction(formData));
    if (imageUpload?.payload?.status !== 200) {
      setSnack(true)
      return (imageUploadError = imageUpload?.payload?.message);
    }
    const createSurveyData = await dispatch(
      createSurveyAction({
        cat_id: cat_id?._id,
        sub_cat_id: sub_cat_id?._id,
        link_qns: linkQnsList,
        reward_amt: Number(data?.reward_amt),
        secondary_reward: Number(data?.secondary_reward),
        reward_type: "INR",
        card_bg_color: [cardBgColor?.gradient1, cardBgColor?.gradient2],
        survey_time: data?.survey_time,
        survey: data?.survey,
        imageUrl: imageUpload?.payload?.data?.url,
      })
    );
    if (createSurveyData?.payload?.status == 201) {
      await dispatch(getSurveyAction({ is_deleted: false }));
      setSnack(true);
      setCardBgColorVisible1(false);
      setCardBgColorVisible2(false);
      reset();
      navigate("/survey");
    }

    setSnack(true);
    // setState(false)
  };

  const handleClose = function () {
    // setState(false)
    reset();
    navigate("/survey");
  };

  const handleChangeCardColor1 = (color) => {
    setCardBgColor({
      ...cardBgColor,
      gradient1: color?.hex,
    });
  };

  const handleChangeCardColor2 = (color) => {
    setCardBgColor({
      ...cardBgColor,
      gradient2: color?.hex,
    });
  };

  useEffect(() => {
    dispatch(getCategoryAction({ is_deleted: false }));
  }, []);

  useEffect(() => {
    let defaultValues = {};
    defaultValues = {
      reward: {
        reward_amt: "0.1",
        secondary_reward: "0.1",
      },
    };

    reset(defaultValues);
  }, []);
  
  return (
    <>
      <Helmet>
        <title>Quick survey | Survey create survey</title>
      </Helmet>
      <Appbar breadcrumb={breadcrumb} />
      <DrawerUI />
      <Body>
        {/* <Card sx={{ width: 500, p: 5 }}> */}
        <Card sx={{ p: 5 }}>
          <Typography variant="h5" sx={{ mb: 5 }}>
            Add Survey
          </Typography>
          <form onSubmit={handleSubmit(handleQns)}>
            <Stack spacing={3}>
              <Autocomplete
                disablePortal
                id={getCatData?._id}
                options={getCatData}
                getOptionLabel={(getCatData) => getCatData?.cat_name}
                onChange={(event, value) => {
                  cat_id = value;
                  dispatch(
                    getSubCategoryAction({
                      cat_id: cat_id?._id,
                      is_deleted: false,
                    })
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category Name"
                    {...register("cat_name")}
                    error={errors?.cat_name?.message}
                    helperText={errors?.cat_name?.message}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                id={getSubCatData?._id}
                options={getSubCatData}
                getOptionLabel={(getSubCatData) => getSubCatData?.sub_cat_name}
                onChange={(event, value) => {
                  sub_cat_id = value;
                  dispatch(getSurveyQnsAction({ sub_cat_id: sub_cat_id?._id }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sub Category Name"
                    error={errors?.sub_cat_name?.message}
                    helperText={errors?.sub_cat_name?.message}
                    {...register("sub_cat_name")}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                options={[1, 2]}
                renderInput={(params) => (
                  <TextField
                    {...register("survey")}
                    {...params}
                    label="Survey"
                    placeholder="Survey"
                    error={errors?.survey?.message}
                    helperText={errors?.survey?.message}
                  />
                )}
              />

              <TextField
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                label="Survey Completion time in minutes"
                variant="outlined"
                placeholder="Survey Completion time in minutes"
                error={errors?.survey_time?.message}
                helperText={errors?.survey_time?.message}
                {...register("survey_time")}
              />

              {/* <Slider marks={[0.1, 1]} disabled={!watch('reward.reward_type')} {...register('reward.reward_amt')} aria-label="Temperature" defaultValue={0.1} valueLabelDisplay="auto" step={0.1} max={3} min={0.1} /> */}
              <TextField
                type="text"
                placeholder="Primary Reward Amount"
                label="Primary Reward Amount"
                {...register("reward_amt")}
                error={errors?.reward_amt?.message}
                helperText={errors?.reward_amt?.message}
              />

              <TextField
                type="text"
                label="Secondary Reward Amount"
                {...register("secondary_reward")}
                error={errors?.secondary_reward?.message}
                helperText={errors?.secondary_reward?.message}
              />
              <TextField
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  if (e.target.files[0]) {
                    setImageRequired(false);
                  }
                }}
                inputProps={{ accept: "image/*" }}
                helperText={imageRequired && "Image is required"}
                error={imageRequired}
              />
              <Box>
                <Typography variant="h6" color="primary">
                  Survey Card Color
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Box>
                    <Button
                      variant="contained"
                      color="info"
                      sx={{ bgcolor: cardBgColor?.gradient1 }}
                      onClick={() => {
                        setCardBgColorVisible1(true);
                        setCardBgColorVisible2(false);
                      }}
                    >
                      Card Gradient 1
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      sx={{ bgcolor: cardBgColor?.gradient2 }}
                      onClick={() => {
                        setCardBgColorVisible2(true);
                        setCardBgColorVisible1(false);
                      }}
                    >
                      Card Gradient 2
                    </Button>
                  </Box>
                </Stack>
                {cardBgColorVisible1 && (
                  <Box sx={{ mt: 2 }}>
                    <ChromePicker
                      color={cardBgColor?.gradient1}
                      onChangeComplete={handleChangeCardColor1}
                    />
                  </Box>
                )}
                {cardBgColorVisible2 && (
                  <Box sx={{ mt: 2 }}>
                    <ChromePicker
                      color={cardBgColor?.gradient2}
                      onChangeComplete={handleChangeCardColor2}
                    />
                  </Box>
                )}
                {errors?.cardBgColor?.message && (
                  <Typography sx={{ mt: 1 }} variant="subtitle2" color="error">
                    {errors?.cardBgColor?.message}
                  </Typography>
                )}
              </Box>

              <Paper
                sx={{
                  bgcolor: "#fff",
                  "& .super-app-theme--header": {
                    bgcolor: "#071D45",
                    color: "#fff",
                  },
                }}
                elevation={0}
              >
                <Typography sx={{ mt: 2 }} variant="h6">
                  Link Questions
                </Typography>
                <DataGrid
                  className={classes.root}
                  checkboxSelection
                  getRowHeight={() => "auto"}
                  sx={{ border: 0 }}
                  autoHeight
                  rows={qnsData}
                  columns={linkedQnsColumn}
                  getRowId={(row) => row._id}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  pagination
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[25, 50, 100]}
                  disableColumnSelector
                  loading={qnsLoading ? <LoadingUI /> : false}
                  onSelectionModelChange={(linkedQns) =>
                    setLinkQnsList(linkedQns)
                  }
                  components={{
                    NoRowsOverlay: () => <NoDataFound />,
                    Toolbar: GridToolbar,
                  }}
                />
              </Paper>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={createSurveyLoading}
                >
                  {createSurveyLoading ? <CircularProgress /> : "Create Survey"}
                </Button>
                <Button variant="contained" color="error" onClick={handleClose}>
                  Back
                </Button>
              </Stack>
            </Stack>
          </form>
        </Card>
      </Body>

      <SnackBarUI
        state={snack}
        setState={setSnack}
        status={createSurveyStatus}
        message={createSurveyMsg || imageUploadError}
      />
    </>
  );
}

CreateSurvey.propTypes = {
  state: PropTypes.any,
};
