import { Card, Paper, Stack, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import { getLogsByIdyAction } from '../../redux/toolkit/profile/getLogsById'
import { useStyles } from '../../theme/theme'

export default function Activity() {
    // state--->
    const dispatch = useDispatch()
    const getLogsByIdySelector = useSelector(state => state.getLogsByIdy)
    const { data, loading } = getLogsByIdySelector

    const [pageSize, setPageSize] = useState(25);
    const classes = useStyles();

    const user_id = JSON.parse(localStorage.getItem("ss_active_usr"))?._id


    const profileGridColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            flex: 1,
            minWidth: 300,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
            flex: 1,
            minWidth: 300,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'method',
            headerName: 'Method',
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{JSON.parse(params?.row?.logs)?.method}</Typography>
                )
            },
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            minWidth: 120,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.role}</Typography>
                )
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{JSON.parse(params?.row?.logs)?.status}</Typography>
                )
            },
        },
        {
            field: 'url',
            headerName: 'Url',
            flex: 1,
            minWidth: 500,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{JSON.parse(params?.row?.logs)?.url}</Typography>
                )
            },
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.created_at?.split("T")?.[0]}</Typography>
                )
            },

        },
        {
            field: 'time',
            headerName: 'Time',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.created_at?.split("T")?.[1]?.split(".")?.[0]}</Typography>
                )
            },
        },
    ]

    // use effect
    useEffect(() => {
        dispatch(getLogsByIdyAction(user_id))
    }, [dispatch])



    return (
        <Card sx={{ my: 2 }}>
            <Stack direction={'row'} alignItems='center' sx={{ ml: 1 }} >
                <Typography sx={{ my: 1 }} variant='h6'>My Activity Logs List</Typography>
            </Stack>

            {/* table list */}
            <Paper sx={{
                bgcolor: '#fff',
                '& .super-app-theme--header': {
                    bgcolor: '#071D45',
                    color: '#fff',
                },
            }} elevation={0}>


                <DataGrid
                    className={classes.root}
                    getRowHeight={() => 'auto'}
                    sx={{ border: 0 }}
                    rows={data}
                    autoHeight
                    columns={profileGridColumns}
                    getRowId={(row) => row._id}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 50, 100]}
                    disableColumnSelector
                    loading={loading ? <LoadingUI /> : false}
                    components={{
                        NoRowsOverlay: () => <NoDataFound />,
                        Toolbar: GridToolbar,
                    }}

                />
            </Paper>
        </Card>
    )
}
