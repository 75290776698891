import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Avatar, Box, Breadcrumbs, Button, Card, Chip, Grid, IconButton, Link, Paper, Stack, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import Appbar from '../../components/Appbar'
import DrawerUI from '../../components/Drawer'
import Body from '../../layout/Body'
import { getPublisherAction } from '../../redux/toolkit/publisher/getPublisher'
import { useStyles } from '../../theme/theme'
import BlockUnblockPublisherDailog from './BlockUnblockPublisherDailog'
import ManegerDetails from './ManegerDetails'
import NoOfPublisher from './NoOfPublisher'
import VerfiedPublisherDailog from './VerfiedPublisherDailog'


const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/publisher'>Publisher</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Publisher</Typography>
    </Stack>
)


export default function Publisher() {
    // state
    const dispatch = useDispatch()
    const getPublisherSelector = useSelector(state => state.getPublisher)
    const { data, loading } = getPublisherSelector


    const [pageSize, setPageSize] = useState(25);
    const classes = useStyles();
    const [blockUnblockAdmin, setBlockUnblockAdmin] = useState(false)
    const [blockAdmin, setBlockAdmin] = useState(false)
    const [verfyAdmin, setVerifyAdmin] = useState(false)
    const [addManegerDetails, setAddManegerDetails] = useState(false)
    const [publisher_id, setpublisher_id] = useState('')
    const [publisherDetails, setPublisherDetails] = useState({})

    const qnsDataGridColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
            flex: 1,
            minWidth: 220,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'publisher_name',
            headerName: 'Publisher Name',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'publisher_email',
            headerName: 'Publisher Email',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'app_name',
            headerName: 'App name',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'profileImg',
            headerName: 'profile Image',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    params?.row?.profile_img
                        ? <Avatar src={params?.row?.profile_img}></Avatar >
                        : <Avatar sx={{ fontSize: 10, bgcolor: 'primary.main' }}>NO Dp</Avatar >
                )
            },
        },
        {
            field: 'platform',
            headerName: 'Platform',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Grid container spacing={1}>
                        {params?.row?.platform?.map((items, index) => {
                            return (
                                <Grid item key={index}>
                                    <Chip label={items} key={index} />
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            },
        },
        {
            field: 'postback_url',
            headerName: 'Postback Url',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
        },

        {
            field: 'maneger_details',
            headerName: 'Maneger Details',
            renderCell: (params) => <Button onClick={() => {
                setAddManegerDetails(true)
                setpublisher_id(params?.row?._id)
                setPublisherDetails(params?.row)
            }} variant='contained' size='small' color='success'>Add Maneger</Button>,
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',

        },
        {
            field: 'block',
            headerName: 'Block / Unblock Publisher',
            renderCell: (params) => {
                return (
                    <>
                        {params?.row?.block
                            ? <IconButton sx={{ borderRadius: 2 }} onClick={() => { setBlockUnblockAdmin(true); setBlockAdmin(false); setpublisher_id(params?.row?._id) }}><Typography color="primary">Unblock</Typography></IconButton>
                            : <IconButton sx={{ borderRadius: 2 }} onClick={() => { setBlockUnblockAdmin(true); setBlockAdmin(true); setpublisher_id(params?.row?._id) }}><Typography color="error">Block</Typography></IconButton>}


                    </>
                )
            },
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',

        },
        {
            field: 'verify',
            headerName: 'Verify Publisher',
            renderCell: (params) => {
                return (
                    <>
                        {params?.row?.varified?.registeration
                            ? <Typography color="primary">Verified</Typography>
                            : <IconButton sx={{ borderRadius: 2 }} onClick={() => { setVerifyAdmin(true); setpublisher_id(params?.row?._id) }}><Typography color="error">Verify</Typography></IconButton>}


                    </>
                )
            },
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',

        },
    ]

    useEffect(() => {
        dispatch(getPublisherAction())
    }, [dispatch])

    return (
        <>
            <Helmet><title> Quick survey | Publisher </title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            <DrawerUI />
            <Body>


                <Box>
                    <Box sx={{ my: 2 }}>
                        <Typography variant='h6'>Publisher Info</Typography>
                        <Typography variant='subtitle2'>Publisher related data here.</Typography>
                    </Box>
                    <Grid container sx={{ my: 3 }} spacing={2}>
                        <Grid item xs={4}>
                            <NoOfPublisher />
                        </Grid>

                    </Grid>
                </Box>

                <Card sx={{ my: 2 }}>
                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: '#071D45',
                            color: '#fff',
                        },
                    }} elevation={0}>
                        <DataGrid
                            className={classes.root}
                            getRowHeight={() => 'auto'}
                            sx={{ border: 0 }}
                            autoHeight
                            rows={data}
                            columns={qnsDataGridColumns}
                            getRowId={(row) => row._id}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            pagination
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[25, 50, 100]}
                            disableColumnSelector
                            loading={loading ? <LoadingUI /> : false}
                            onCellClick={(params) => {
                                setpublisher_id(params?.row?._id)
                            }}
                            components={{
                                NoRowsOverlay: () => <NoDataFound />,
                                Toolbar: GridToolbar,
                            }}

                        />
                    </Paper>
                </Card>
            </Body>

            {blockUnblockAdmin && < BlockUnblockPublisherDailog blockAdmin={blockAdmin} publisher_id={publisher_id} state={blockUnblockAdmin} setState={setBlockUnblockAdmin} />}
            {verfyAdmin && < VerfiedPublisherDailog publisher_id={publisher_id} state={verfyAdmin} setState={setVerifyAdmin} />}
            {addManegerDetails && <ManegerDetails publisher_id={publisher_id} publisherDetails={publisherDetails} state={addManegerDetails} setState={setAddManegerDetails} />}
        </>
    )
}
