import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import "../../css/ui/Modal.scss";
import { getCategoryAction } from '../../redux/toolkit/category/getCategory';
import { updateCategoryAction } from '../../redux/toolkit/category/updateCategory';
import PropTypes from 'prop-types';

// schema
const catSchema = yup.object({
    cat_name: yup.string().required('Category name is required.'),
}).required();

export default function UpdateCategory({ cat_id, state, setState }) {
    // state
    const dispatch = useDispatch()

    const updateCat = useSelector(state => state.updateCategory)
    const { status, message, loading } = updateCat
    const snglCat = useSelector(state => state.getSingleCategory)
    const { data: { cat_name } } = snglCat


    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(catSchema),
        defaultValues: {
            cat_name: cat_name
        }
    });

    const [snack, setSnack] = useState(false)

    // fn
    const handleCategory = async (data) => {

        await dispatch(updateCategoryAction({ ...data, cat_id: cat_id }))
        await dispatch(getCategoryAction({ is_deleted: false }))
        setSnack(true)
        setState(false)
    }

    const handleClose = function () {
        setState(false)
    }


    // useEffect
    useEffect(() => {
        let defaultValues = {};
        defaultValues.cat_name = cat_name;
        reset({ ...defaultValues });
    }, [cat_name])



    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 400, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Update Category</Typography>
                        <form onSubmit={handleSubmit(handleCategory)}>
                            <Stack spacing={3}>

                                <TextField label="Category" variant="outlined"  {...register('cat_name')} error={errors?.cat_name?.message} helperText={errors?.cat_name?.message} />
                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Update Category'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose} >Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>



            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
            <DevTool control={control} />
        </>
    )
}

UpdateCategory.propTypes = {
    cat_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any
};
