import {
    Breadcrumbs,
    Button,
    Card,
    Link,
    Paper,
    Stack,
    Typography,
  } from "@mui/material";
  
  import React, { useEffect, useState } from "react";
  import { useParams } from "react-router-dom";
  import { Helmet } from "react-helmet-async";
  
  import DrawerUI from "../../components/Drawer";
  import Body from "../../layout/Body";
  import Appbar from "../../components/Appbar";
  import NavigateNextIcon from "@mui/icons-material/NavigateNext";
  
  import { useDispatch, useSelector } from "react-redux";

  import { DataGrid } from "@mui/x-data-grid";

 
  import SnackBarUI from "../../comman/SnackBarUI";
import { getAllQuestionAnswerAction } from "../../redux/toolkit/question-answer/getAllQuestionAnswer";
import { linkQuestionToSurveyAction } from "../../redux/toolkit/survey/linkQuestions";
  
  const breadcrumb = (
    <Stack>
      <Breadcrumbs
        separator={
          <NavigateNextIcon sx={{ color: "inherit" }} fontSize="small" />
        }
        aria-label="breadcrumb"
      >
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link underline="hover" color="inherit" href="/survey">
          Survey
        </Link>
        <Link underline="hover" color="inherit" href="/survey-detail">
          Survey details
        </Link>
      </Breadcrumbs>
      <Typography variant="h5">Unlink Questions</Typography>
    </Stack>
  );
  const LinkQuestions = () => {
    const { surveyId } = useParams();
    const { message,status, loading } = useSelector((state) => state.linkQuestionToSurvey);
    const { data } = useSelector((state) => state. getAllQuestionAnswer);
    const [questionsId, setQuestionsId] = useState({});
    const[snake,setSnake] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getAllQuestionAnswerAction());
    }, []);
    const getSelectedQuestionsId = (ids) => {
      setQuestionsId({questionsId:ids,survey_id:surveyId});
    };
    console.log(data, loading);
    console.log(questionsId, "qns");
    const unlinkSurveyQuestions = async () => {
      if (!questionsId) {
        return;
      }
      await dispatch(linkQuestionToSurveyAction(questionsId));
      await  dispatch(getAllQuestionAnswerAction());
      setSnake(true)
  
    };
    console.log(data)
    const columns = [
      {
        field: "_id",
        headerName: "ID",
  
        minWidth: 250,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "question",
        headerName: "Questions",
  
        minWidth: 650,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "Category",
        headerName: "Category Name",
  
        minWidth: 100,
        headerClassName: "super-app-theme--header",
        renderCell:(params)=>{
          return(
            <Typography>{params?.row?.cat_id?.cat_name}</Typography>
          )
        }
      },
      {
        field: "link",
        headerName: "Linked",
  
        minWidth: 100,
        headerClassName: "super-app-theme--header",
      },
    ];
  
    return (
      <>
        <Helmet>
          <title>Quick survey | Unlink Questions</title>
        </Helmet>
        <Appbar breadcrumb={breadcrumb} />
        <DrawerUI />
       
          <Body>
            <Card sx={{ p: 3, bgcolor: "#fff" }}>
              <Stack
                sx={{
                  width: "100%",
                  justifyContent: "flex-end",
                }}
                direction={"row"}
              >
                <Button
                  color="success"
                  sx={{
                    width: "150px",
                  }}
                  disabled = {loading}
                  variant="contained"
                  onClick={unlinkSurveyQuestions}
                >
                  {"Link"}
                </Button>
              </Stack>
              <br />
              <Paper
                sx={{
                  bgcolor: "#fff",
                  "& .super-app-theme--header": {
                    bgcolor: "#071D45",
                    color: "#fff",
                  },
                }}
                elevation={0}
              >
                <DataGrid
                  rows={data?.filter((val)=>val.link === false)}
                  columns={columns}
                  autoHeight
                  disableRowSelectionOnClick
                  checkboxSelection
                  getRowId={(row) => row._id}
                  onSelectionModelChange={(ids) => getSelectedQuestionsId(ids)}
                />
              </Paper>
            </Card>
          </Body>
       
       
        <SnackBarUI message={message} state={snake} setState={setSnake} status={status}/>
      </>
    );
  };
  
  export default LinkQuestions;
  