import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAxios from "../../../config/authAxios";

const initialState = {
  status: null,
  loading: false,
  data: [],
  error: "",
  message: "",
};

export const blockUserAction = createAsyncThunk(
  "block-user",
  async (data, { rejectWithValue }) => {
    try {
      const res = await authAxios.patch(`support-server/api/block-user`,data);
      return res?.data;
    } catch (error) {
      return rejectWithValue(error?.response.data);
    }
  }
);

const blockUserSlice = createSlice({
    name:"block-user",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(blockUserAction.pending,(state)=>{
            state.loading = true
        })
        builder.addCase(blockUserAction.fulfilled, (state, action) => {
            state.loading = false
            state.data = action?.payload?.data
            state.status = action?.payload?.status
            state.message = action?.payload?.message
        })
        builder.addCase(blockUserAction.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.status = action?.payload?.status
            state.message = action?.payload?.message
        })
    }

})
export const blockUserReducer= blockUserSlice.reducer;