import CachedIcon from '@mui/icons-material/Cached';
import { Card, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { diffentPlatformPublisherAction } from '../../redux/toolkit/dashbaord/diffentPlatformPublisher';
import { Graph_PublisherFromDeffPlatform } from './Graph_PublisherFromDeffPlatform';

export default function PublisherFromDeffPlatfomr() {
    // state
    const dispatch = useDispatch()


    // fn
    const handleRefresh = () => {
        dispatch(diffentPlatformPublisherAction())
    }

    // useEffect
    useEffect(() => {
        dispatch(diffentPlatformPublisherAction())
    }, [dispatch])


    return (
        <Card sx={{ p: 2, color: '#fff' }}>
            <Stack direction={'row'} justifyContent='space-between'>
                <Typography variant='h6' color='#5E6C8A' sx={{fontFamily: 'Montserrat,sans-serif'}}>Different Platform Publishers</Typography>
                <IconButton onClick={handleRefresh} sx={{ mb: 1, color: '#fff' }}><CachedIcon /></IconButton>
            </Stack>
            <Graph_PublisherFromDeffPlatform />
        </Card>
    )
}
