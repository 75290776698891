import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const liveSurveyInBulkAction = createAsyncThunk(
    'live-survey-in-bulk',
    async (data = null, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`/support-server/api/live-survey-in-bulk`, { ...data })
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const liveSurveyInBulkSlice = createSlice({
    name: 'live-survey-in-bulk',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(liveSurveyInBulkAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(liveSurveyInBulkAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(liveSurveyInBulkAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const liveSurveyInBulkReducer = liveSurveyInBulkSlice.reducer    