import { Breadcrumbs, Button, Card, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import Appbar from '../../components/Appbar'
import DrawerUI from '../../components/Drawer'
import Body from '../../layout/Body'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box } from '@mui/system'
import { useState } from 'react'
import { uplodImageAction } from '../../redux/toolkit/image/uplodImage'
import { paymentRequestsAction } from '../../redux/toolkit/payments/paymentRequests'
import { creditPaymentAction } from '../../redux/toolkit/payments/creditPayment'
import { useDispatch } from 'react-redux'
import ImageIcon from '@mui/icons-material/Image';

const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/payments'>Payments</Link>
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/payments/credit-payments'>Credit payments</Link>
        </Breadcrumbs >
        <Typography variant='h5'>Payments</Typography>
    </Stack >
)

export default function CreditPayment() {

    // state
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    let payment_id = searchParams.get('payment_id')
    let publisher_id = searchParams.get('publisher_id')


    const [invoice, setinvoice] = useState();
    const [invoicePreview, setinvoicePreview] = useState();


    // fn
    const handleImage = async (e) => {

        const formData = new FormData();
        formData.set("file", e.target.files[0]);
        let uploadedImg = await dispatch(uplodImageAction(formData))

        setinvoicePreview(URL.createObjectURL(e.target.files[0]));
        setinvoice(uploadedImg?.payload?.data?.url)
    }


    const handleCreditAmount = async () => {
        const creditPayment = await dispatch(creditPaymentAction({ payment_id: payment_id, publisher_id: publisher_id, invoice: invoice }))
        if (creditPayment?.payload?.status == 200) {
            await dispatch(paymentRequestsAction({ is_deleted: false }))
            navigate("/payments")
        }
    }

    return (
        <>
            <Helmet><title>Quick survey | credit-payments</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            <DrawerUI />
            <Body>
                <Card sx={{ p: 2, mt: 2 }}>
                    <Typography variant='h6' color='primary'>Credit payment Reqest</Typography>

                    <Box sx={{ mt: 2 }}>
                        <input id="upload-avatar-pic" onChange={handleImage} type='file' accept="image/*" hidden />
                        <label htmlFor="upload-avatar-pic">

                            <Stack direction={'row'} alignItems='center' sx={{ bgcolor: 'primary.main', color: '#fff', p: 1, width: 'fit-content', borderRadius: 1 }}>
                                <Typography>Upload Invoice</Typography>
                                <ImageIcon />
                            </Stack>

                        </label>

                        <Box sx={{ my: 1 }}>
                            <Typography variant='subtitle2'>Image : </Typography>
                            <img width={'100%'} src={invoicePreview || invoice} />
                        </Box>
                        <Stack direction={'row'} spacing={2}>
                            <Button type='submit' variant='contained' onClick={handleCreditAmount}>Credit Payment</Button>
                            <Button variant='contained' color='error' onClick={() => { navigate("/payments") }}>Back</Button>
                        </Stack>
                    </Box>
                </Card>

            </Body>
        </>
    )
}
