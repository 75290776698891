import { DevTool } from '@hookform/devtools'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import RestoreIcon from '@mui/icons-material/Restore'
import { Breadcrumbs, Button, Card, IconButton, Link, Menu, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import SnackBarUI from '../../comman/SnackBarUI'
import Appbar from '../../components/Appbar'
import Drawer from '../../components/Drawer'
import { dateConvert } from '../../helper/dateConverter'
import Body from '../../layout/Body'
import { changeCatSeqAction } from '../../redux/toolkit/category/changeCategorySequence'
import { getCategoryAction } from '../../redux/toolkit/category/getCategory'
import { getSingleCategoryAction } from '../../redux/toolkit/category/getSingleCategory'
import { useStyles } from '../../theme/theme'
import CreateCategory from './CreateCategory'
import DeleteCategory from './DeleteCategory'
import ReStoreCategory from './ReStoreCategory'
import UpdateCategory from './UpdateCategory'



const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} href='/category'>Category</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Category</Typography>
    </Stack>
)




export default function Category() {
    // state--->
    const { register, reset, control, handleSubmit } = useForm({});
    const permission = useSelector(state => state.getPermissionAndRole)
    const { data: permissionData } = permission

    const dispatch = useDispatch()
    const cat = useSelector(state => state.getCategory)
    const { data: catData, loading: catDataLoading } = cat
    const catSeq = useSelector(state => state.changeCatSeq)
    const { status: catSeqStatus, message: catSeqMsg } = catSeq

    let can_delete = JSON.parse(localStorage.getItem("ss_active_usr"))?.settings?.can_delete
    let super_admin = permissionData?.role


    const [snack, setSnack] = useState(false)
    const [catMenu, setCatMenu] = useState(false)
    const [createCat, setCreateCat] = useState(false)
    const [updateCat, setUpdateCat] = useState(false)
    const [delCat, setDelCat] = useState(false)
    const [restoreCat, setRestoreCat] = useState(false)
    const [anchorCatMenu, setAnchorCatMenu] = useState()
    const [ativeFilter, setActiveFilter] = useState(false)
    const [pageSize, setPageSize] = useState(25);
    const [cat_id, seCat_id] = useState('')
    const classes = useStyles();
    const [date, setDate] = useState({
        start: null,
        end: null,
    })



    const catDataGridColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'cat_name',
            headerName: 'Name',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'sub_cat',
            headerName: 'Sub Category',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <>
                        <Typography>
                            {params?.row?.sub_cat?.length}
                        </Typography>
                    </>
                )
            },
        },
        {
            field: 'totalLinksQns',
            headerName: 'Total Questions',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'no_of_survey',
            headerName: 'Number Of Survey',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'sequence_number',
            headerName: 'Sequence of Category',
            renderCell: (params) => {
                return (
                    <>
                        <TextField
                            sx={{ my: 2 }} type='number' label="Category Sequence" size='small'
                            defaultValue={params?.row?.sequence_number}
                            variant="outlined"
                            placeholder='Category Sequence'
                            {...register(params?.row?._id)}
                        />
                    </>
                )
            },
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
            hide: ativeFilter
        },

        {
            field: 'edit', headerName: 'Edit Category',
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                            setUpdateCat(true);
                            dispatch(getSingleCategoryAction(params?.row?._id))
                        }}><EditIcon color='primary' />
                    </IconButton>
                )
            },
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 150,
            hide: ativeFilter
        },
        {
            field: 'delete', headerName: 'Delete Category', renderCell: () => {
                return (
                    <IconButton
                        onClick={() => {
                            setDelCat(true);
                        }}><DeleteIcon color='error' />
                    </IconButton>
                )
            },
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 150,
            hide: ativeFilter || (!can_delete && super_admin != 'super_admin')

        },
        {
            field: 'revert',
            headerName: 'Revert Category',
            renderCell: () => {
                return (
                    <IconButton
                        onClick={() => {
                            setRestoreCat(true);
                        }}
                    ><RestoreIcon color='success' />
                    </IconButton>
                )
            },
            headerClassName: 'super-app-theme--header',
            flex: 1,
            hide: !ativeFilter

        },
    ]


    // fn
    const handleCatChangeSeq = async (data) => {

        const changeCat = await dispatch(changeCatSeqAction(data))
        if (changeCat?.payload?.status) {
            await dispatch(getCategoryAction({ is_deleted: false }))
            reset()
            setSnack(true)
        }

    }

    const handleclearFilter = () => {
        setActiveFilter(false)
        dispatch(getCategoryAction({ is_deleted: false }))
    }

    const handleFilterData = () => {
        dispatch(getCategoryAction({ is_deleted: false, s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
    }

    // useEffect
    useEffect(() => {
        dispatch(getCategoryAction({ is_deleted: false }))
    }, [dispatch])



    return (
        <>
            <Helmet><title>Quick survey | Category</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            <Drawer />
            <Body>

                <Card sx={{ p: 3, bgcolor: '#071D45', color: '#fff' }}>
                    <Typography variant='h6'>Category section</Typography>
                    <Typography variant='subtitle1'>You can create category from here.</Typography>
                    <Button sx={{ mt: 2, bgcolor: '#fff', color: '#000' }} variant='contained' onClick={() => setCreateCat(true)}>Create Category</Button>
                </Card>

                <Card sx={{ my: 2 }}>
                    <Stack direction={'row'} alignItems='center' sx={{ ml: 1 }} >
                        <Typography sx={{ my: 1 }} variant='h6'>Category List</Typography>
                        <Box sx={{ m: 2 }}>
                            {(can_delete || super_admin == 'super_admin')
                                &&
                                <>
                                    <IconButton color='error' onClick={handleclearFilter}><ClearAllIcon /></IconButton>
                                    <IconButton
                                        onClick={(event) => {
                                            setCatMenu(true);
                                            setAnchorCatMenu(event.currentTarget);
                                        }}>
                                        <FilterAltIcon color='warning' />
                                    </IconButton>
                                </>


                            }

                            <Menu
                                open={catMenu}
                                onClose={() => setCatMenu(false)}
                                anchorEl={anchorCatMenu}
                            >
                                <MenuItem selected={ativeFilter}
                                    onClick={() => {
                                        setCatMenu(false)
                                        dispatch(getCategoryAction({ is_deleted: true }))
                                        setActiveFilter(true)
                                    }} >Deleted</MenuItem>
                                <MenuItem selected={!ativeFilter}
                                    onClick={() => {
                                        setCatMenu(false)
                                        dispatch(getCategoryAction({ is_deleted: false }))
                                        setActiveFilter(false)
                                    }}>Non Deleted</MenuItem>
                            </Menu>
                        </Box>
                    </Stack>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ p: 2 }}>
                            <Typography color='#071D45'>Filter by Date</Typography>
                            <Stack direction={'row'} spacing={2} sx={{ mt: 1 }} alignItems='center'>
                                <DatePicker
                                    label="Start Date"
                                    value={date?.start}
                                    onChange={(value) => setDate({ ...date, start: value })}
                                    renderInput={(params) => <TextField {...params} />}
                                    maxDate={date?.end}

                                />
                                <DatePicker
                                    label="End Date"
                                    value={date?.end}
                                    onChange={(value) => setDate({ ...date, end: value })}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDate={date?.start}
                                />
                                <Box>
                                    <IconButton onClick={handleFilterData} color='#071D45'><FilterAltIcon /></IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </LocalizationProvider>

                    {/* table list */}
                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: '#071D45',
                            color: '#fff',
                        },
                    }} elevation={0}>


                        <DataGrid
                            className={classes.root}
                            sx={{
                                border: 0,
                            }}
                            getRowHeight={() => 'auto'}
                            autoHeight
                            rows={catData}
                            columns={catDataGridColumns}
                            getRowId={(row) => row._id}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            pagination
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[25, 50, 100]}
                            disableColumnSelector
                            loading={catDataLoading ? <LoadingUI /> : false}
                            onCellClick={(params) => {
                                seCat_id(params?.row?._id)
                            }}
                            components={{
                                NoRowsOverlay: () => <NoDataFound />,
                                Toolbar: GridToolbar,

                            }}

                        />
                    </Paper>
                </Card>

                {!ativeFilter && catData?.length ? <Button onClick={handleSubmit(handleCatChangeSeq)} sx={{ my: 2 }} variant='contained' color='error'>Change Category Sequence</Button> : null}

                {/* crud */}
                <CreateCategory state={createCat} setState={setCreateCat} />
                <UpdateCategory cat_id={cat_id} state={updateCat} setState={setUpdateCat} />
                <DeleteCategory cat_id={cat_id} state={delCat} setState={setDelCat} />
                <ReStoreCategory cat_id={cat_id} state={restoreCat} setState={setRestoreCat} resetCatSeq={reset} />
            </Body >


            <SnackBarUI state={snack} setState={setSnack} status={catSeqStatus} message={catSeqMsg} />
            <DevTool control={control} />
        </>
    )
}
