// import LiveTvIcon from '@mui/icons-material/LiveTv';

import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingUI from '../../comman/LoadingUI';
import {FcSurvey} from 'react-icons/fc'

import { noOfTotalLiveSurveyAction } from '../../redux/toolkit/menu/noOfTotalSurvey';

export default function TotalSurvey() {
    // state
    const dispatch = useDispatch()


    const liveSurveySelector = useSelector(state => state.noOfToatlSurveys)
    const { data, loading } = liveSurveySelector

    // useeffect
    useEffect(() => {
        dispatch(noOfTotalLiveSurveyAction())
    }, [dispatch])


    return (
        <Grid item xs={4}>
            <Card sx={{ p: 2, bgcolor:"#071D45"}}>
                <Stack spacing={2} alignItems={'center'}>
                    <Box sx={{ width: 70, height: 70, borderRadius: 1, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <LiveTvIcon sx={{ fontSize: 50 }} /> */}
                        <FcSurvey style={{
                            fontSize:"4rem"
                        }}/>

                    </Box>
                    <Typography variant='subtitle2' color="#fff">Total Survey</Typography>
                    {loading ? <LoadingUI /> : null}
                    <Typography variant='h3' color='#fff'>{data || "--"}</Typography>
                </Stack>
            </Card>
        </Grid >
    )
}
