import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,

    DialogTitle,
    Stack,
    TextField,
  } from "@mui/material";
  import React, { useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import SnackBarUI from "../../comman/SnackBarUI";
import * as yup from 'yup'
  import { getSurveyAction } from "../../redux/toolkit/survey/getSurvey";
  import PropTypes from "prop-types";
import { editSurveyRewardsAction } from "../../redux/toolkit/survey/editRewards";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
 
  const rewardsSchema = yup.object({
    primaryRewards:yup.number().required().typeError("Primary Reward Is Required"),
    secondaryRewards:yup.number().required().typeError("Primary Reward Is Required")
  })
  export default function EditSurveyRewards({ survey_id, state, setState }) {
    // state
    const dispatch = useDispatch();
    
    const {message,status}  = useSelector((state) => state.getrewards);
    const [snack, setSnack] = useState(false);
   const {register,reset, handleSubmit, formState : {errors}} = useForm({resolver:yupResolver(rewardsSchema)})
  
    // fn
    const handleClose = () => {
      setState(false);
    };
 
  
   
  
    const handleUpdateRewards = async (data) => {
        console.log( "data",data)
      const updateSurveyRewards = await dispatch(editSurveyRewardsAction({survey_id:survey_id,primaryRewards:data?.primaryRewards,secondaryRewards:data?.secondaryRewards}));
      if (updateSurveyRewards?.payload?.status == 200) {
        await dispatch(getSurveyAction({ is_deleted: false }));
        reset()
      }
      setState(false)
      setSnack(true);
     
    };
  
    return (
      <>
        <Dialog open={state} onClose={handleClose} fullWidth>
          <DialogTitle>Edit The Rewards!</DialogTitle>
          <DialogContent>
            <form  onSubmit={handleSubmit(handleUpdateRewards)}>
                <br />
                <Stack direction={"column"}  gap={2}>
                <TextField type="number" {...register("primaryRewards")} label = {"Primary Rewards"} error = {errors?.primaryRewards?.message} helperText = {errors?.primaryRewards?.message} />
                <TextField type="number" {...register("secondaryRewards") } label = "Secondary Rewards" error = {errors?.secondaryRewards?.message} helperText = {errors?.secondaryRewards?.message} />
                </Stack>
                <br />
                 <DialogActions>
              <Button  variant={"contained"}  type="submit" >
                
                Update
              </Button>
              <Button onClick={handleClose}  variant={"contained"} sx={{
                bgcolor:"red",
                
              }}>Cancel</Button>
            </DialogActions>
            </form>
          </DialogContent>
     
           
       
        </Dialog>
  
        <SnackBarUI
          state={snack}
          setState={setSnack}
          status={status}
          message={message}
        />
      </>
    );
  }
  
  EditSurveyRewards.propTypes = {
    survey_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any,
  };
  