import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const surveyStatusAction = createAsyncThunk(
    'survey-status',
    async (data = null, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`/support-server/api/survey-status`, { ...data })
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const surveyStatusSlice = createSlice({
    name: 'survey-status',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(surveyStatusAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(surveyStatusAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(surveyStatusAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const surveyStatusReducer = surveyStatusSlice.reducer    