import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import RestoreIcon from '@mui/icons-material/Restore'
import SearchIcon from '@mui/icons-material/Search'
import { Autocomplete, Box, Breadcrumbs, Button, Card, Grid, IconButton, Link, Menu, MenuItem, Paper, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useCSVReader } from 'react-papaparse'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import SnackBarUI from '../../comman/SnackBarUI'
import Appbar from '../../components/Appbar'
import Drawer from '../../components/Drawer'
import { dateConvert } from '../../helper/dateConverter'
import Body from '../../layout/Body'
import { getCategoryAction } from '../../redux/toolkit/category/getCategory'
import { getAllQuestionAnswerAction } from '../../redux/toolkit/question-answer/getAllQuestionAnswer'
import { getSingleQuestionAnswerAction } from '../../redux/toolkit/question-answer/getSingleQuestionAnswer'
import { uploadQnsAnsAction } from '../../redux/toolkit/question-answer/uploadQnsAns'
import { useStyles } from '../../theme/theme'
import CreateDefaultQns from './CreateDefaultQns'
import CreateQuestionAnswer from './CreateQuestionAnswer'
import DeleteQns from './DeleteQns'
import ReStoreQns from './ReStoreQns'
import UpdateQnsAns from './UpdateQnsAns'
import UpdateAnswer from './UpdateAnswer'
import { getSubCategoryAction } from '../../redux/toolkit/sub-category/getSubCategory'



const breadcrumb = (
  <Stack>
    <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
      <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
      <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/qns-ans'>Q & A</Link>
    </Breadcrumbs>
    <Typography sx={{ color: "#5E6C8A" }} variant='h4'>Q & A</Typography>
  </Stack>
)


let sub_cat = '';

export default function QuestionAnswer() {
  // state--->
  const { register, getValues, formState: { errors } } = useForm({});

  const dispatch = useDispatch()
  const qnsAns = useSelector(state => state.getAllQuestionAnswer)
  const { data: qnsData, loading: qnsLoading } = qnsAns
  const getCat = useSelector(state => state. getSubCategory)
  const { data: getSubCatData } = getCat
  const uploadQnsAns = useSelector(state => state.uploadQnsAns)
  const { status: uploadQnsAnsStatus, message: uploadQnsAnsMessage } = uploadQnsAns
  const permission = useSelector(state => state.getPermissionAndRole)
  const { data: permissionData } = permission

  let can_delete = JSON.parse(localStorage.getItem("ss_active_usr"))?.settings?.can_delete
  let super_admin = permissionData?.role

  const [createQns, setCreateQns] = useState(false)
  const [createDefaultQns, setCreateDefaultQns] = useState(false)
  const [updateQns, setUpdateQns] = useState(false)
  const [delQns, setDelQns] = useState(false)
  const [catMenu, setCatMenu] = useState(false)
  const [anchorCatMenu, setAnchorCatMenu] = useState()
  const [ativeFilter, setActiveFilter] = useState(false)
  const [restoreCat, setRestoreCat] = useState(false)
  const [pageSize, setPageSize] = useState(25);
  const [qns_id, setqns_id] = useState('')
  const classes = useStyles();
  const [date, setDate] = useState({
    start: null,
    end: null,
  })
  const [qnsCSVData, setQnsCSVData] = useState([])
  const [snack, setSnack] = useState(false)
  const[editAnswer,setEditAnswer] = useState(false)
  const { CSVReader } = useCSVReader();

console.log(sub_cat)
  const qnsDataGridColumns = [
    {
      field: 's_no',
      headerName: 'S. No.',
      filterable: false,
      flex: 1,
      minWidth: 30,
      headerClassName: 'super-app-theme--header',
      renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
    },
    {
      field: '_id',
      headerName: 'ID',
      flex: 1,
      minWidth: 150,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'question',
      headerName: 'Question',
      flex: 1,
      minWidth: 150,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'ans_options',
      headerName: 'Answer Options',
      flex: 1,
      minWidth: 150,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'cat_id',
      headerName: 'Category Name',
      flex: 1,
      minWidth: 150,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <Typography>{params?.row?.cat_id?.cat_name}</Typography>
        )
      },
    },
    {
      field: 'link',
      headerName: 'Linked',
      flex: 1,
      minWidth: 150,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <>
            {params?.row?.link ? <CheckIcon color='success' /> : <ClearIcon color='error' />}
          </>
        )
      },
    },
    {
      field: 'sub_cat_id',
      headerName: 'Sub Category Name',
      flex: 1,
      minWidth: 150,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <Typography>{params?.row?.sub_cat_id?.sub_cat_name}</Typography>
        )
      },
    },
    {
      field: 'question_reletive_cat',
      headerName: 'Question Relative Cateogry',
      flex: 1,
      minWidth: 200,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'answer_type',
      headerName: 'Answer Type',
      flex: 1,
      minWidth: 150,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'correct_answer',
      headerName: 'Correct Answer',
      flex: 1,
      minWidth: 150,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'edit', headerName: 'Edit Questions',
      renderCell: (params) => {
        return (
          <IconButton onClick={() => {
            setUpdateQns(true);
            dispatch(getSingleQuestionAnswerAction(params?.row?._id))
          }}><EditIcon color='primary' />
          </IconButton>
        )
      },
      flex: 1,
      minWidth: 150,
      headerClassName: 'super-app-theme--header',
      hide: ativeFilter
    },
    {
      field: 'edit-answer', headerName: 'Edit Options',  minWidth: 150,  flex: 1,  headerClassName: 'super-app-theme--header', renderCell: () => {
        return (
          <IconButton
            onClick={(params) => {
              setEditAnswer(true)
              setqns_id(params?.row?._id)
            }}>
            <EditIcon color='primary' /></IconButton>
        )
      },
     

    },
    {
      field: 'delete', headerName: 'Delete Category', renderCell: () => {
        return (
          <IconButton
            onClick={(params) => {
              setDelQns(true)
              setqns_id(params?.row?._id)
            }}>
            <DeleteIcon color='error' /></IconButton>
        )
      },
      flex: 1,
      minWidth: 150,
      headerClassName: 'super-app-theme--header',
      hide: ativeFilter || (!can_delete && super_admin != 'super_admin')

    },
    
    {
      field: 'revert',
      headerName: 'Revert Questions',
      renderCell: () => {
        return (
          <IconButton
            onClick={() => {
              setRestoreCat(true);
            }}><RestoreIcon color='success' /></IconButton>
        )
      },
      flex: 1,
      minWidth: 150,
      headerClassName: 'super-app-theme--header',
      hide: !ativeFilter
    },
  ]



  // function 
  const handleFilter = () => {
    dispatch(getAllQuestionAnswerAction({ sub_cat_id: sub_cat, answer_type: getValues('answer_type'), is_deleted: ativeFilter }))
  }
  const handleclearFilter = () => {
    setActiveFilter(false)
    dispatch(getAllQuestionAnswerAction({ is_deleted: false }))
  }

  const handleFilterData = () => {
    dispatch(getAllQuestionAnswerAction({ is_deleted: false, s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
  }

  const handleUploadQns = async () => {
    let uploadQns = await dispatch(uploadQnsAnsAction(qnsCSVData))
    if (uploadQns?.payload?.status == 200) {
      dispatch(getAllQuestionAnswerAction({ is_deleted: false }))
    }
    setSnack(true)
  }

  // use effect 
  useEffect(() => {
    dispatch(getAllQuestionAnswerAction({ is_deleted: false }))
    dispatch(getSubCategoryAction({ is_deleted: false }))
  }, [dispatch])



  return (
    <>
      <Helmet><title> Quick survey | Q & A</title></Helmet>
      <Appbar breadcrumb={breadcrumb} />
      <Drawer />
      <Body>

        <Card sx={{ p: 3, bgcolor: '#071D45', color: '#fff' }}>
          <Typography variant='h6'>Q & A section</Typography>
          <Typography variant='subtitle1'>You can create question and answer from here.</Typography>
          <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
            <Button sx={{ bgcolor: '#fff', color: '#000' }} variant='contained' onClick={() => { setCreateQns(true); dispatch(getCategoryAction({ is_deleted: false })) }}>Create Question</Button>
            <Button variant='contained' onClick={() => { setCreateDefaultQns(true) }} color='error' > Create Default Question</Button>
          </Stack>
        </Card>

        {/* table list */}

        <Card sx={{ my: 2 }}>
          <Grid container sx={{ p: 2 }}>
            <Grid item xs={6}>
              <Stack direction='row' spacing={2} alignItems='center'>
                {/* <TextField fullWidth label="Category"  {...register('cat_name')} /> */}
                <Autocomplete fullWidth disablePortal id={getSubCatData?._id} options={getSubCatData} getOptionLabel={getSubCatData => getSubCatData?.sub_cat_name}
                  onChange={(event, value) => {
                    sub_cat = value?._id
                  }}
                  renderInput={(params) => <TextField {...register('cat_name')} {...params} label="Sub Category Name" error={errors?.cat_name?.message} helperText={errors?.cat_name?.message} />} />
                {/* <TextField fullWidth label="Answer Type"  {...register('answer_type')} /> */}
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <IconButton type='submit' onClick={handleFilter} color='primary'><SearchIcon /></IconButton>
              <IconButton color='#071D45' onClick={handleclearFilter}><ClearAllIcon /></IconButton>
              {(can_delete || super_admin == 'super_admin') && < IconButton
                onClick={(event) => {
                  setCatMenu(true);
                  setAnchorCatMenu(event.currentTarget);
                }}>
                <FilterAltIcon color='#071D45' /></IconButton>
              }

              <Menu
                open={catMenu}
                onClose={() => setCatMenu(false)}
                anchorEl={anchorCatMenu}
              >
                <MenuItem selected={ativeFilter}
                  onClick={() => {
                    setCatMenu(false)
                    setActiveFilter(true)
                    dispatch(getAllQuestionAnswerAction({ is_deleted: true }))

                  }} >Deleted</MenuItem>
                <MenuItem selected={!ativeFilter}
                  onClick={() => {
                    setCatMenu(false)
                    setActiveFilter(false)
                    dispatch(getAllQuestionAnswerAction({ is_deleted: false }))
                  }}>Non Deleted</MenuItem>
              </Menu>
            </Grid>
          </Grid>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ p: 2 }}>
              <Typography color='#071D45'>Filter by Date</Typography>
              <Stack direction={'row'} spacing={2} sx={{ mt: 1 }} alignItems='center'>
                <DatePicker
                  label="Start Date"
                  value={date?.start}
                  onChange={(value) => setDate({ ...date, start: value })}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={date?.end}

                />
                <DatePicker
                  label="End Date"
                  value={date?.end}
                  onChange={(value) => setDate({ ...date, end: value })}
                  renderInput={(params) => <TextField {...params} />}
                  minDate={date?.start}
                />
                <Box>
                  <IconButton onClick={handleFilterData} color='#071d45'><FilterAltIcon /></IconButton>
                </Box>
              </Stack>
            </Box>
          </LocalizationProvider>


          <Card sx={{ mt: 1, p: 2, bgcolor: '#071D45' }}>
            <Stack sx={{ my: 2 }}>
              <Typography variant='subtitle1' sx={{ color: '#fff' }}>Upload Questions</Typography>
              <Typography variant='subtitle2' sx={{ color: '#fff' }}>Drag / Upload / Drop CSV</Typography>

              <Stack sx={{ my: 1, ml: 2 }}>
                <Typography variant='subtitle2' sx={{ color: '#fff' }}>Note *</Typography>
                <Typography variant='subtitle2' sx={{ color: '#fff' }}>1 column = question </Typography>
                <Typography variant='subtitle2' sx={{ color: '#fff' }}>2 column = Category Id </Typography>
                <Typography variant='subtitle2' sx={{ color: '#fff' }}>3 column = Sub Category Id </Typography>
                <Typography variant='subtitle2' sx={{ color: '#fff' }}>4 column = Answer Type </Typography>
                <Typography variant='subtitle2' sx={{ color: '#fff' }}>{`5 column = Answer Options exp : ['Yes', 'No']`} </Typography>
              </Stack>
            </Stack>
            <CSVReader
              onUploadAccepted={(results) => {
                setQnsCSVData(results?.data)
              }}
              onDragOver={(event) => {
                event.preventDefault();
                setQnsCSVData(event?.data)
              }}
              onDragLeave={(event) => {
                event.preventDefault();
                setQnsCSVData(event?.data)
              }}
            // noClick
            >
              {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove, }) => (
                <>
                  <Box {...getRootProps()} spacing={1}>
                    <Button variant='contained' color='error' endIcon={<CloudUploadIcon />}>Upload CSV</Button>
                    {acceptedFile?.name
                      ? <>
                        <Stack direction={'row'} spacing={2} sx={{ my: 2 }} alignItems={'center'}>
                          <Typography sx={{ color: '#fff' }}>{acceptedFile?.name}</Typography>
                          {acceptedFile?.name ? <IconButton  {...getRemoveFileProps()}><Remove /></IconButton> : null}
                        </Stack>
                        <ProgressBar />
                      </>
                      : null}

                  </Box>
                </>
              )}
            </CSVReader>

            <Button sx={{ mt: 1 }} onClick={handleUploadQns} disabled={!qnsCSVData?.length} variant='contained' color='error' endIcon={<CloudUploadIcon />}>Upload Questions</Button>
          </Card>



          <Paper sx={{
            bgcolor: '#fff',
            '& .super-app-theme--header': {
              bgcolor: '#071D45',
              color: '#fff',
            },
          }} elevation={0}>
            <DataGrid
              className={classes.root}
              getRowHeight={() => 'auto'}
              sx={{ border: 0 }}
              rows={qnsData}
              autoHeight
              columns={qnsDataGridColumns}
              getRowId={(row) => row._id}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              pagination
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[25, 50, 100]}
              disableColumnSelector
              loading={qnsLoading ? <LoadingUI /> : false}
              onCellClick={(params) => {
                setqns_id(params?.row?._id)
              }}
              components={{
                NoRowsOverlay: () => <NoDataFound />,
                Toolbar: GridToolbar,
              }}
            />
          </Paper>
        </Card>


        {/* crud */}
        <CreateQuestionAnswer state={createQns} setState={setCreateQns} />
        <CreateDefaultQns state={createDefaultQns} setState={setCreateDefaultQns} />
        <UpdateQnsAns qns_id={qns_id} state={updateQns} setState={setUpdateQns} />
        <DeleteQns qns_id={qns_id} state={delQns} setState={setDelQns} />
        <ReStoreQns qns_id={qns_id} state={restoreCat} setState={setRestoreCat} />
        <UpdateAnswer qns_id={qns_id} state={editAnswer} setState={setEditAnswer}/>

      </Body >

      <SnackBarUI state={snack} setState={setSnack} status={uploadQnsAnsStatus} message={uploadQnsAnsMessage} />
    </>
  )
}
