// import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import Attempts from '../../assets/icon/attempts.png'
import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import LoadingUI from '../../comman/LoadingUI'
import PropTypes from 'prop-types';

export default function NoOfSurveyAttempts({ data, loading }) {


    return (
        <Grid item xs={4}>
            <Card sx={{ p: 2,bgcolor:"#071D45" }}>
                <Stack spacing={2} alignItems={'center'}>
                    <Box sx={{ width: 70, height: 70, borderRadius: 1, bgcolor: '#071D45', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <PlaylistAddCheckIcon sx={{ fontSize: 50 }} /> */}
                        <img src={Attempts} style={{minWidth:"10px"}}/>
                    </Box>
                    <Typography variant='subtitle2' color="#fff">Number of survey Attempts</Typography>
                    {loading ? <LoadingUI /> : null}
                    <Typography variant='h3' color='#fff'>{data || "--"}</Typography>
                </Stack>
            </Card>
        </Grid>
    )
}


NoOfSurveyAttempts.propTypes = {
    data: PropTypes.any,
    loading: PropTypes.any
};