import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Autocomplete, Button, Card, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton, InputAdornment, InputLabel, Modal, OutlinedInput, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { getAdminSupportAction } from '../../redux/toolkit/admin/getAdminSupports';
import { registerUserAction } from '../../redux/toolkit/auth/registerUser';



const permissionArr = [
    'category',
    'sub_category',
    'qns_and_ans',
    'survey',
    'settings',
    'analytics',
    'menu',
    'publisher',
    'postback',
    'payments'
]

// schema
const registerScbema = yup.object({
    name: yup.string().required(),
    email: yup.string().required(),
    password: yup.string().required(),
    // security_key: yup.string().required(),
    settings: yup.object({
        can_delete: yup.boolean().required(),
    })
}).required();


export default function CreateAdmin({ state, setState }) {
    // state
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(registerScbema) });

    const dispatch = useDispatch()
    const registerUserSelector = useSelector(state => state.registerUser)
    const { status, message, loading } = registerUserSelector

    const [snack, setSnack] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [permission, setPermission] = useState([])

    // fn
    const hanldeCreateAdminSupport = async (data) => {
        const registerData = await dispatch(registerUserAction({ permission: permission, ...data }))
        if (registerData?.payload?.status == 200) {
            await dispatch(getAdminSupportAction())
            setSnack(true)
            setState(false)
        }
        setSnack(true)
    }

    const handleClose = function () {
        setState(false)
    }


    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 600, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Create Admin / Support</Typography>
                        <form onSubmit={handleSubmit(hanldeCreateAdminSupport)}>
                            <Stack spacing={3}>
                                <Stack spacing={2}>
                                    <TextField label="Name" variant="outlined" placeholder='Name' {...register('name')} error={errors?.name?.message} helperText={errors?.name?.message} />
                                    <TextField label="Email" variant="outlined" placeholder='Email' {...register('email')} error={errors?.email?.message} helperText={errors?.email?.message} />

                                    <FormControl variant="outlined">
                                        <InputLabel>Password</InputLabel>
                                        <OutlinedInput
                                            {...register('password')} error={errors?.password?.message}
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                    >
                                                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                        <FormHelperText error="error">{errors?.password?.message}</FormHelperText>
                                    </FormControl>
                                </Stack>


                                <FormGroup>
                                    <FormControlLabel {...register('settings.can_delete')} control={<Switch />} label="Deleting functionallty to admin" />
                                </FormGroup>

                                <Autocomplete
                                    disablePortal
                                    multiple
                                    aria-multiline
                                    options={permissionArr}
                                    onChange={(event, value) => setPermission(value)}
                                    renderInput={(params) => <TextField {...params} label="Add Permission" />}
                                />

                                {/* <TextField label="Security Key" variant="outlined" placeholder='Security Key' {...register('security_key')} error={errors?.security_key?.message} helperText={errors?.security_key?.message} /> */}
                                <FormControl>
                                    <FormLabel>Role</FormLabel>
                                    <RadioGroup >
                                        <Stack direction={'row'}>
                                            <FormControlLabel value="support" control={<Radio />} label="Support" {...register('role')} />
                                            <FormControlLabel value="admin" control={<Radio />} label="Admin" {...register('role')} />
                                        </Stack>
                                        {errors?.role?.message && <Typography color='error'>{errors?.role?.message}</Typography>}
                                    </RadioGroup>
                                </FormControl>

                                <Stack direction={'row'} spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Create Admin'}</Button>
                                    <Button variant='contained' color='error' onClick={() => setState(false)}> Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>

            </Modal>
            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}

CreateAdmin.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any
};
